import React from "react";
import styles from "./LayerProgress.module.css";

interface LayerProgressProps {
  currentLayer: number;
}

const LayerProgress: React.FC<LayerProgressProps> = ({ currentLayer }) => {
  const totalLayers = 7;
  const progressPercentage = (currentLayer / totalLayers) * 100;

  return (
    <div className={styles.layerProgress}>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <div className={styles.steps}>
        {Array.from({ length: totalLayers }).map((_, index) => (
          <div
            key={index}
            className={`${styles.step} ${
              index + 1 <= currentLayer ? styles.completed : ""
            }`}
          >
            <span className={styles.stepNumber}>{index + 1}</span>
            <div className={styles.tooltip}>
              {`Layer ${index + 1}`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LayerProgress;
