import React from "react";
import styles from "./AnimatedIllustration.module.css";

const AnimatedIllustration: React.FC = () => {
  return (
    <div className={styles.illustrationContainer}>
      <div className={styles.glowingCircle}>
        <div className={styles.spinner}></div>
      </div>
      <p className={styles.text}>Finding your perfect Bridger...</p>
    </div>
  );
};

export default AnimatedIllustration;
