import React, { useState, useEffect} from "react";
import styles from "../../styles/Auth.module.css";
import axios from "axios";
import Select from "react-select";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import popperImg from "../../assets/SignupPage/party_popper2.png";
import popupBG from "../../assets/SignupPage/popupBG.png";
import video from "../../assets/Login/loginBG5.mp4";


interface Language {
  language_id: number;
  language_name: string;
}

interface FormErrors {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  preferredLanguage: string;
  knownLanguages: string;
  general:string;
}
interface FormField {
  name: string;
  type: string;
  placeholder: string;
  value: string;
  setState: (value: string) => void;
  autoComplete?: string;
}

const SignupForm: React.FC = () => {
  //Inline styles for react-select for preferred & known languages
  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%", // Set the width of the container to 100%
      fontFamily: "Poppins",
    }),
    control: (provided) => ({
      ...provided,
      width: "100%", // Set the width of the control part of react-select to 100%
      background: "transparent",
      border: "1px solid whitesmoke", // Customize border color
      borderRadius: "30px", // Customize border radius
      padding: "0 10px", // Add padding inside the control box
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
      borderRadius: "8px",
      padding: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
      margin: "0",
    }),
    // You can customize other parts like the dropdown, placeholder, etc.
    option: (provided) => ({
      ...provided,
      padding: " 8px 10px", // Customize option padding
      margin: "0",
      backgroundColor: "transparent",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white", // Customize selected value color
      fontSize: "clamp(0.7rem, 0.65rem + 0.2vw, 1rem)",

    }),
    placeholder: (provided) => ({
      ...provided,
      color: "whitesmoke", // Customize placeholder color
      lwtterSpacing: "1.5px",
      // fontSize: '0.9rem',
      fontSize: "clamp(0.7rem, 0.65rem + 0.2vw, 0.9rem)",
      fontWeight: "500",
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator if needed
    }),
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState<number | null>(
    null
  );
  const [knownLanguages, setKnownLanguages] = useState<number[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
    const [isLoading, setIsLoading ] = useState<boolean>(false)
  const [languages, setLanguages] = useState<Language[]>([]);
  const [otp, setOtp] = useState<string>("");
  const [otpSent,setOtpSent] = useState<boolean>(false)
  const [otpError, setOtpError] = useState<string>("");
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    preferredLanguage: "",
    knownLanguages: "",
    general:"",
  });

  const navigate = useNavigate();



  const formFields: FormField[] = [
    {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      value: firstname,
      setState: setFirstname,
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      value: lastname,
      setState: setLastname,
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email",
      value: email,
      setState: setEmail,
      autoComplete: "new-email",
    },
    {
      name: "password",
      type: showPassword ? "text" : "password",
      placeholder: "Password",
      value: password,
      setState: setPassword,
      autoComplete: "new-email",
    },
    {
      name: "confirmPassword",
      type: showConfirmPassword ? "text" : "password",
      placeholder: "Confirm Password",
      value: confirmPassword,
      setState: setConfirmPassword,
    },
  ];

  const backendURL = "https://totle-website-backend2.onrender.com";

  //Fetching Languages from Backend
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get<Language[]>(`${backendURL}/languages`);
        setLanguages(response.data);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  //TO clear the form fields and error states on successful signup
  const clearFormData = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setPreferredLanguage(null);
    setKnownLanguages([]);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      preferredLanguage: "",
      knownLanguages: "",
      general:"",
    });
  };

  //Handle Signup Form
  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    //Reset all errors initially
    const newErrors: FormErrors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      preferredLanguage: "",
      knownLanguages: "",
      general:"",
    };

    let hasError = false;

    //Validate each field individually
    if (!firstname) {
      newErrors.firstName = "First name is required";
      hasError = true;
    }
    if (!lastname) {
      newErrors.lastName = "Last name is required";
      hasError = true;
    }
    if (!email) {
      newErrors.email = "Email is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
      hasError = true;
    }

    if (!password) {
      newErrors.password = "Password is required!";
      hasError = true;
    } else if (password.length < 8 || !/[!@#$%^&*]/.test(password)) {
      newErrors.password =
        "Password must be at least 8 characters long and include a special character";
      hasError = true;
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Please Confirm your password!";
      hasError = true;
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      hasError = true;
    }

    if (!preferredLanguage) {
      newErrors.preferredLanguage = "Please select your preferred language";
      hasError = true;
    }

    if (knownLanguages.length === 0) {
      newErrors.knownLanguages = "Please select at least one known language";
      hasError = true;
    }
    //set errors
    setErrors(newErrors);
    //If there are any errors, update the state and stop form submission
    if (hasError) {
      setIsLoading(false)
      return;
    }

    //Proceed with signup if no errors
    const userData = {
      firstname,
      lastname,
      email,
      password,
      preferredLanguage,
      knownLanguages,
    };
    try {

      //For initialting the OTP, not creating the account
      const response = await axios.post(`${backendURL}/signup-user`, userData);
     
      if(response.data.message && response.data.message.includes("OTP sent") ){
        setOtpSent(true)   
      }else{
        const errorMsg = response.data.message || "Failed to send OTP. Please try again.";
        throw new Error("Failed to send OTP. Please try again.", errorMsg);
      } 
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data.message || "Signup failed. Please try again.";
       
          //Check if the error is about existing email
          if (error.response?.status === 409 || 
            errorMessage.toLowerCase().includes('email') || 
            errorMessage.toLowerCase().includes('already registered') ||
            errorMessage.toLowerCase().includes('exists')) {
          // Set error specifically for email field
          setErrors({
            ...newErrors,
            email: "This email is already registered. Please use a different email or login."
          });
        } else {
          setErrors({
            ...newErrors,
            general: errorMessage,
          })
        }

   
      } else {
        setErrors({
          ...newErrors,
          general: "Signup Failed!. Please try again.",
        });
      }
    }finally{
      setIsLoading(false)
    }
  };


      //OTP verification Function

      const verifyOtp = async () => {
        if(!otp){
          setOtpError("OTP required!!")
          return;
        }

        setIsLoading(true);
        try{ 
            const verificationData = {
              email,
              otp: parseInt(otp, 10),
              firstname,
              lastname,
              preferredLanguage,
              knownLanguages,
              password,
            }


            //When OTP is verified the account is created
          const response = await axios.post(`${backendURL}/verify-signup`, verificationData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
           )

          if(response.status === 201 || response.data.success){
            clearFormData()
            setSuccessPopup(true);
            setTimeout(() => {
              setSuccessPopup(false);
              navigate("/login");
            },6000)
          }else{
            setOtpError( response.data.message || "Invalid OTP. Please try again.")
          }
        }catch(error){

          if(axios.isAxiosError(error)){
           
            const errorMessage = error.response?.data.messgae || "Invalid OTP. Please try again."
            setOtpError(errorMessage)
          }else{
          console.error("Non-axios error:", error)
            setOtpError("Email verification failed!. Please try again.")
          
          }
        }finally{
          setIsLoading(false)
        }
      }


  useEffect(() => {
    clearFormData()
  }, []);

  //Convert languages array to React select format
  const languageOptions = languages.map((lang) => ({
    value: lang.language_id,
    label: lang.language_name,
  }));


  //Handle mail otp verification popup
  const handleCancelOtp = () => {
    setOtpSent(false); // Reset OTP sent state if needed
    setOtp(""); // Clear OTP input field
    setIsOtpVerified(false); // Reset OTP verification state
    setOtpError("")
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className={styles.authPage}>
      <video className={styles.backgroundVideo} autoPlay loop muted playsInline>
        <source src={video} />
        Your browser does not support the video tag.
      </video>

      <div className={styles.signupChildContainer}>
        <div className={styles.signupHeading}>
          <h2>Create an Account</h2>
          <p>Join us and start your journey</p>
        </div>
        <form onSubmit={handleSignup}>
          <div className={styles.signupFormContainer}>
            {formFields.map((field) => (
              <div key={field.name} className={styles.signupFormChildContainer}>
                <div className={styles.inputWrapper}>
                  <input
                    autoComplete="off"
                    type={
                      field.name === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : field.name === "confirmPassword"
                        ? showConfirmPassword
                          ? "text"
                          : "password"
                        : field.type
                    }
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e) => field.setState(e.target.value)}
                  />
                  {field.name === "password" && (
                    <button
                      type="button"
                      className={styles.passwordToggle}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? "🙈" : "👁️"}
                    </button>
                  )}
                  {field.name === "confirmPassword" && (
                    <button
                      type="button"
                      className={styles.passwordToggle}
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? "🙈" : "👁️"}
                    </button>
                  )}
                </div>
                {errors[field.name] && (
                  <span className={styles.errorMessage}>
                    {errors[field.name]}
                  </span>
                )}
              </div>
            ))}

            {/* Preferred Language */}
            <div className={styles.signupFormChildContainer}>
              <Select
                className={styles.reactSelect}
                styles={selectStyles}
                options={languageOptions}
                value={languageOptions.find(
                  (option) => option.value === preferredLanguage
                )}
                onChange={(option) =>
                  setPreferredLanguage(option ? option.value : null)
                }
                placeholder="Preferred Language"
                isClearable
              />
              {errors.preferredLanguage && (
                <span className={styles.errorMessage}>
                  {errors.preferredLanguage}
                </span>
              )}
            </div>

            {/* Known Languages */}
            <div className={styles.signupFormChildContainer}>
              <Select
                isMulti
                className={styles.reactSelect}
                styles={selectStyles}
                options={languageOptions}
                value={languageOptions.filter((option) =>
                  knownLanguages.includes(option.value)
                )}
                onChange={(options) => {
                  const selectedIds = options.map((option) => option.value);
                  setKnownLanguages(selectedIds);
                }}
                placeholder="Known Languages"
              />
              {errors.knownLanguages && (
                <span className={styles.errorMessage}>
                  {errors.knownLanguages}
                </span>
              )}
            </div>
          </div>
          <div className={styles.signupSubmitBtn}>
            <button type="submit" disabled={isLoading} aria-busy={isLoading} > 
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </button>
            <div className={styles.loginBtn}>
              <p>
                Already have an account? <a href="/login">Login</a>
              </p>
            </div>
          </div>
        </form>

        {otpSent && !isOtpVerified && (
    <div className={styles.otpVerificationContainer}>
      <div className={styles.cancelBtn} >
      <button
      className={styles.closeOtpButton}
      onClick={handleCancelOtp} // Function to cancel the OTP process
    >
      <RxCross2/>
    </button>
    </div>
      <p>Check your mail for otp.</p>
      <div className={styles.inputWrapper}>
        <input
          type="number"
          placeholder="Enter OTP"
          value={otp}
          // onChange={(e) => setOtp(e.target.value)}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
            if (value.length <= 6) { // Assuming OTP is 6 digits
              setOtp(value);
              setOtpError(''); // Clear any previous error when user types
            }
          }}
          maxLength={6}
          pattern="[0-9]"
          inputMode="numeric"
          autoComplete="one-time-code"
        />
         {otpError && <span className={styles.errorMessage}>{otpError}</span>}
      </div>
      <button
        type="button"
        onClick={verifyOtp}
        disabled={isLoading || !otp }
        className={styles.submitBtn}
      >
        {isLoading ? "Verifying OTP..." : "Verify OTP"}
      </button>
    </div>
  )}

        {successPopup && (
          <div
            className={styles.successPopup}
            style={{
              backgroundImage: `url(${popupBG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width:"250px",
              height:"150px",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              textAlign: "center",
              animation: "fadeIn 0.3s ease-in-out",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
              padding: "0",

            }}
          >
            <img src={popperImg} alt="" />
            <h2>Welcome to Totle buddy!</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupForm;
