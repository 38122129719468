import React, { useState } from "react";
import styles from "./SessionBooking.module.css";
import CatalogueLayer from "./CatalogueLayer";
import FreePlatform from "./FreePlatform";
import PaidPlatform from "./PaidPlatform";

const SessionBooking: React.FC = () => {
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [platformType, setPlatformType] = useState<"free" | "paid" | null>(null);

  const handleTopicSelect = (topic: string) => {
    setSelectedTopic(topic);
    setPlatformType(null);
  };

  const handlePlatformSelect = (type: "free" | "paid") => {
    setPlatformType(type);
  };

  return (
    <div className={styles.sessionBooking}>
      <h2>Session Booking</h2>

      {/* Catalogue Navigation */}
      {!selectedTopic && !platformType && (
        <CatalogueLayer onTopicSelect={handleTopicSelect} />
      )}

      {/* Platform Selection */}
      {selectedTopic && !platformType && (
        <div className={styles.platformSelection}>
          <h3>Choose Your Platform</h3>
          <button
            className={styles.freeButton}
            onClick={() => handlePlatformSelect("free")}
          >
            Free Platform
          </button>
          <button
            className={styles.paidButton}
            onClick={() => handlePlatformSelect("paid")}
          >
            Paid Platform
          </button>
        </div>
      )}

      {/* Free Platform */}
      {platformType === "free" && <FreePlatform topic={selectedTopic!} />}

      {/* Paid Platform */}
      {platformType === "paid" && <PaidPlatform topic={selectedTopic!} />}
    </div>
  );
};

export default SessionBooking;
