import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import styles from "./FiltersAndSearchBar.module.css";

// Type for dropdown options
type Option = { label: string; value: string };

// Mock API Calls (Replace with actual API integration)
const fetchOptions = async (level: string, parentValue: string = ""): Promise<Option[]> => {
  const options: Record<string, Option[]> = {
    state: [
      { label: "Karnataka", value: "karnataka" },
      { label: "Maharashtra", value: "maharashtra" },
    ],
    city: [
      { label: "Bangalore", value: "bangalore" },
      { label: "Mumbai", value: "mumbai" },
    ],
    language: [
      { label: "English", value: "english" },
      { label: "Hindi", value: "hindi" },
    ],
    category: [
      { label: "Academics", value: "academics" },
      { label: "Exam Prep", value: "exam-prep" },
      { label: "Skills", value: "skills" },
      { label: "Arts", value: "arts" },
    ],
    academics: [
      { label: "School", value: "school" },
      { label: "College", value: "college" },
    ],
    school: [
      { label: "CBSE", value: "cbse" },
      { label: "ICSE", value: "icse" },
    ],
    grade: [
      { label: "Grade 1", value: "grade-1" },
      { label: "Grade 2", value: "grade-2" },
    ],
    university: [
      { label: "Delhi University", value: "delhi-university" },
      { label: "Mumbai University", value: "mumbai-university" },
    ],
    subject: [
      { label: "Math", value: "math" },
      { label: "Science", value: "science" },
    ],
    topic: [
      { label: "Algebra", value: "algebra" },
      { label: "Geometry", value: "geometry" },
    ],
  };
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(options[level] || []);
    }, 500);
  });
};

const FiltersAndSearchBar: React.FC = () => {
  const [region, setRegion] = useState({ state: "", city: "" });
  const [language, setLanguage] = useState("");
  const [category, setCategory] = useState("");
  const [dynamicFilters, setDynamicFilters] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  console.log(language,category)

  // Load options for `AsyncSelect` dynamically
  const loadOptions = async (inputValue: string, level: string, parentValue: string = "") => {
    const options = await fetchOptions(level, parentValue);
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleDynamicFilterChange = async (level: string, value: string) => {
    // Update the selected filter
    setSelectedFilters((prev) => ({ ...prev, [level]: value }));

    let nextFilters: string[] = [];
    if (level === "category") {
      switch (value) {
        case "academics":
          nextFilters = ["academics"];
          break;
        case "exam-prep":
          nextFilters = ["exam", "subject", "topic"];
          break;
        case "skills":
          nextFilters = ["skills", "specific-skill", "subject", "topic"];
          break;
        case "arts":
          nextFilters = ["art-type", "level", "topic"];
          break;
        default:
          nextFilters = [];
      }
    } else if (value === "school") {
      nextFilters = ["board", "grade", "subject", "topic"];
    } else if (value === "college") {
      nextFilters = ["university", "specific-college", "course", "semester", "subject", "topic"];
    }

    // Append new filters without losing the existing ones
    setDynamicFilters((prev) => [...prev.slice(0, dynamicFilters.indexOf(level) + 1), ...nextFilters]);
  };

  const resetFilters = () => {
    setRegion({ state: "", city: "" });
    setLanguage("");
    setCategory("");
    setDynamicFilters([]);
    setSelectedFilters({});
  };

  return (
    <div className={styles.filtersContainer}>
      {/* Static Filters Row */}
      <div className={styles.row}>
        <AsyncSelect
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, "state")}
          defaultOptions
          onChange={(option: Option | null) =>
            setRegion((prev) => ({ ...prev, state: option?.value || "" }))
          }
          placeholder="Select State"
          className={styles.asyncSelect}
        />
        <AsyncSelect
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, "city", region.state)}
          defaultOptions
          onChange={(option: Option | null) =>
            setRegion((prev) => ({ ...prev, city: option?.value || "" }))
          }
          placeholder="Select City"
          className={styles.asyncSelect}
        />
        <AsyncSelect
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, "language")}
          defaultOptions
          onChange={(option: Option | null) => setLanguage(option?.value || "")}
          placeholder="Select Language"
          className={styles.asyncSelect}
        />
        <button className={styles.resetButton} onClick={resetFilters}>
          Reset
        </button>
      </div>

      {/* Dynamic Filters Row */}
      <div className={styles.row}>
        <AsyncSelect
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, "category")}
          defaultOptions
          onChange={(option: Option | null) =>
            handleDynamicFilterChange("category", option?.value || "")
          }
          placeholder="Select Category"
          className={styles.asyncSelect}
        />
        {dynamicFilters.map((filter, index) => (
          <AsyncSelect
            key={index}
            cacheOptions
            loadOptions={(inputValue) =>
              loadOptions(inputValue, filter, selectedFilters[filter])
            }
            defaultOptions
            onChange={(option: Option | null) =>
              handleDynamicFilterChange(filter, option?.value || "")
            }
            placeholder={`Select ${filter.replace(/-/g, " ")}`}
            className={styles.asyncSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default FiltersAndSearchBar;
