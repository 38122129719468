import React from 'react';
import styles from './PlatformPage.module.css';
import Navbar from "../../components/Navbar/Navbar"; // Import the new Navbar component
import teacherImg from '../../assets/Platform/Teacher/teacher2.svg'



interface platform{
  title: string;
  description: string;
  image: string;
  link: string;
  buttonText: string;
}

const platforms: platform[] = [
  // {
  //   title: 'Marketplace',
  //   description:
  //     'Explore trending topics, analytics, and available educators. Find a vibrant marketplace of educational tools and resources.',
  //   image: marketPlaceImg, // Adjust based on your file structure
  //   link: '/marketplace',
  //   buttonText: 'Enter Marketplace',
  // },
  {
    title: 'Teacher Platform',
    description:
      'Join or explore the Teachers Platform for educators of all kinds. Connect with peers, access resources, and expand your knowledge base.',
    image: teacherImg,
    link: '/teach',

    buttonText: 'Enter Teacher Platform',
  },
  // {
  //   title: 'Learner Platform',
  //   description:
  //     'Your personalized learning dashboard and sessions. Access tailored resources, track progress, and achieve your learning goals.',
  //     image: teacherImg,
  //   link: '/learn',
  //   buttonText: 'Enter Learner Platform',
  // },
  // {
  //   title: 'Endeavour Platform',
  //   description:
  //     'Participate in competitions, portfolio building, and projects. Showcase your skills, collaborate with others, and grow professionally.',
  //     image: endeavourImg,
  //   link: '/endeavour',
  //   buttonText: 'Enter Endeavour Platform',
  // },
];

const PlatformPage: React.FC = (): JSX.Element => {

  return (
    <div className={styles.platformPage}>
      <Navbar />

    <div className={styles.platformChildContainer} >
  
        <div className={styles.platformCardsContainer} >
          {
            platforms.map(({title, description, image, link, buttonText},index) => (
              <div key={title} className={styles.platformCardsChildContainer}>
                  <div className={`${styles.platformCardsChild} ${styles[`card${index + 1}`]}`}>
                    <div className={styles.platformCards} >
                    {/* <h2>{title}</h2> */}
                    <div className={styles.imageContainer} >
                    <img src={image} alt="" />
                    </div>
                    <div className={styles.description} >
                      <p>{description}</p>
                      <a href={link}>{buttonText}</a>
                    </div>
                  </div>
                  </div>
                 </div>
            ) )
          }
        </div>

    </div>
    </div>
  );
};

export default PlatformPage;
