import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import styles from "./Navbar.module.css";
// import styles from "./Navbaro.module.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import NotificationsPopup from "../NotificationsPopup/NotificationsPopup";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useUser();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const getCurrentPlatform = () => {
    const path = location.pathname;
    if (path.includes("/marketplace")) return "marketplace";
    if (path.includes("/teach")) return "teach";
    if (path.includes("/learn")) return "learn";
    if (path.includes("/endeavour")) return "endeavour";
    return "platform";
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handlePlatformChange = (platform: string) => {
    if (platform !== "platform") {
      navigate(`/${platform}`);
    }
  };

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
   
    <>
      <nav>
        <div className={styles.navbarContainer}>
          <div className={styles.navLeft}>
            <div className={styles.logo} >
              <h1>Totle</h1>
            </div>
            <div className={styles.platformSwitcher}>
            <select
              className={styles.switcherSelect}
              value={getCurrentPlatform()}
              onChange={(e) => handlePlatformChange(e.target.value)}
            >
              <option value="platform" disabled>
                Platform
              </option>
              <option value="marketplace">Marketplace</option>
              <option value="teach">Teacher Platform</option>
              <option value="learn">Learner Platform</option>
              <option value="endeavour">Endeavour Platform</option>
            </select>
            </div>
          </div>

         {/* Hamburger Menu */}
         <div className={styles.hamburgerIcon} onClick={handleMobileMenuToggle} >
          {
            showMobileMenu ? <RxCross2/> : <RxHamburgerMenu/>
          }
         </div>

          <div className={styles.navRight}>
            <div className={styles.rightOptions}>
              <button onClick={() => setShowNotifications((prev) => !prev)} >Notification</button>
              <button onClick={() => navigate("/profile")} >Profile</button>
              <button onClick={handleLogout} className={styles.logout} >Logout</button>
            </div>
          </div>
        </div>
      </nav>
         {showNotifications && (
        <NotificationsPopup onClose={() => setShowNotifications(false)} />
      )}

      {
        showMobileMenu && (
          <div className={styles.mobileMenu} >
            <div className={styles.mobileMenuUpperPart}>
            <button onClick={() => setShowNotifications((prev) => !prev)} >Notification</button>
            <button onClick={() => navigate("/profile")} >Profile</button>
            </div>
              <div className={styles.mobileMenuLowerPart}>
              <button onClick={handleLogout} className={styles.logout} >Logout</button>
              </div>
          </div>
        )
      }
    </>
  );
};

export default Navbar;
