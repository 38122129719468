import React, { useState } from "react";
import styles from "./PortfolioBuilding.module.css";

interface Section {
  id: number;
  title: string;
  content: string;
}

const PortfolioBuilding: React.FC = () => {
  const [sections, setSections] = useState<Section[]>([
    { id: 1, title: "About Me", content: "Write a brief introduction about yourself." },
    { id: 2, title: "Skills", content: "List your skills here." },
    { id: 3, title: "Projects", content: "Add your top projects with descriptions and links." },
  ]);

  const addSection = () => {
    const newSection: Section = {
      id: Date.now(),
      title: "New Section",
      content: "Edit this section to add content.",
    };
    setSections((prev) => [...prev, newSection]);
  };

  const deleteSection = (id: number) => {
    setSections((prev) => prev.filter((section) => section.id !== id));
  };

  const updateSection = (id: number, content: string) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === id ? { ...section, content } : section
      )
    );
  };

  return (
    <div className={styles.portfolioBuilding}>
      <h2>Portfolio Building</h2>
      <p>Create and maintain a professional portfolio to showcase your work.</p>

      <div className={styles.portfolioEditor}>
        {sections.map((section) => (
          <div key={section.id} className={styles.sectionCard}>
            <h3>{section.title}</h3>
            <textarea
              value={section.content}
              onChange={(e) => updateSection(section.id, e.target.value)}
              className={styles.textArea}
            />
            <button
              onClick={() => deleteSection(section.id)}
              className={styles.deleteButton}
            >
              Delete Section
            </button>
          </div>
        ))}
        <button onClick={addSection} className={styles.addButton}>
          Add Section
        </button>
      </div>

      <div className={styles.preview}>
        <h3>Portfolio Preview</h3>
        {sections.map((section) => (
          <div key={section.id} className={styles.previewSection}>
            <h4>{section.title}</h4>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioBuilding;
