import React, { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styles from "./Dashboard.module.css";

const Dashboard: React.FC = () => {
  const [notifications] = useState([
    { id: 1, text: "Project ABC updated with new comments", type: "update" },
    { id: 2, text: "Collaboration request from John Doe", type: "invite" },
    { id: 3, text: "80% completed on AI Project", type: "achievement" },
  ]);

  const collaborators = [
    { name: "Alice Johnson", skills: "AI, ML", recentProject: "AI Chatbot" },
    { name: "Bob Smith", skills: "Web Development", recentProject: "E-commerce Site" },
    { name: "Charlie Lee", skills: "Data Analysis", recentProject: "Market Analysis" },
  ];

  return (
    <div className={styles.dashboard}>
      {/* Personalized Feed */}
      <section className={styles.feed}>
        <h3>Personalized Feed</h3>
        <div className={styles.cards}>
          <div className={styles.card}>
            <h4>Recommended Project</h4>
            <p>AI-driven E-learning Platform</p>
            <button className={styles.cardButton}>Explore</button>
          </div>
          <div className={styles.card}>
            <h4>Skills to Level Up</h4>
            <p>Data Visualization</p>
            <button className={styles.cardButton}>Learn Now</button>
          </div>
        </div>
      </section>

      {/* Task Reminders and Notifications */}
      <section className={styles.notifications}>
        <h3>Notifications</h3>
        <ul>
          {notifications.map((notification) => (
            <li key={notification.id} className={styles[notification.type]}>
              {notification.text}
            </li>
          ))}
        </ul>
      </section>

      {/* Discover Collaborators */}
      <section className={styles.collaborators}>
        <h3>Discover Collaborators</h3>
        <div className={styles.collaboratorCards}>
          {collaborators.map((collab, index) => (
            <div key={index} className={styles.collaboratorCard}>
              <h4>{collab.name}</h4>
              <p>Skills: {collab.skills}</p>
              <p>Recent Project: {collab.recentProject}</p>
              <button className={styles.cardButton}>Invite</button>
            </div>
          ))}
        </div>
      </section>

      {/* Employability Rating */}
      <section className={styles.employability}>
        <h3>Employability Rating</h3>
        <div className={styles.ratingChart}>
          <CircularProgressbar value={80} text={`80%`} />
          <p>Your skills are market-ready!</p>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
