import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTachometerAlt,
  FaChalkboardTeacher,
  FaBookOpen,
  FaBell,
  FaStar,
  FaRegCalendarAlt,
  FaMoneyBill,
  FaPencilAlt,
  FaHistory,
} from "react-icons/fa";
import styles from "./TeachSidebar.module.css";

const TeachSidebar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { icon: <FaTachometerAlt />, label: "Dashboard", route: "/teach/dashboard" },
    { icon: <FaChalkboardTeacher />, label: "Teaching", route: "/teach/teaching" },
    { icon: <FaBookOpen />, label: "Test Platform", route: "/teach/test-platform" },
    {
      icon: <FaRegCalendarAlt />,
      label: "Session Management",
      route: "/teach/session-management",
    },
    { icon: <FaBookOpen />, label: "Teaching Resources", route: "/teach/resources" },
    { icon: <FaStar />, label: "Feedbacks and Ratings", route: "/teach/feedbacks" },
    { icon: <FaBell />, label: "Notifications", route: "/teach/notifications" },
    { icon: <FaMoneyBill />, label: "Earnings and Analytics", route: "/teach/earnings" },
    { icon: <FaPencilAlt />, label: "Content Creation", route: "/teach/content" },
    { icon: <FaHistory />, label: "Story", route: "/teach/story" },
  ];

  return (
    <div
      className={`${styles.sidebar} ${expanded ? styles.expanded : ""}`}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={styles.menuItem}
          onClick={() => navigate(item.route)}
        >
          <span className={styles.icon}>{item.icon}</span>
          {expanded && <span className={styles.label}>{item.label}</span>}
        </div>
      ))}
    </div>
  );
};

export default TeachSidebar;
