import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import Navbar from "../Navbar/Navbar";


interface ProfileSection {
  id: string;
  name: string;
  visibility: "Public" | "Platform-only" | "Private";
  data: any;
}

const Profile: React.FC = () => {

  const [profileData, setProfileData] = useState<ProfileSection[]>([]);
  const [profileCompletion, setProfileCompletion] = useState(60);
  const [userImage, setUserImage] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userid'); // You'd get this from the authenticated user context
        const response = await fetch(`https://totle-website-backend2.onrender.com/get-user/${userId}`);
        const data = await response.json();
        console.log(data)

        if (response.ok) {
          const sections: ProfileSection[] = [
            {
              id: "basic",
              name: "Basic Information",
              visibility: "Public",
              data: {
                name: `${data.firstname} ${data.lastname}`,
                email: data.email,
                dob: "1995-05-15", // You can get this from backend as well if needed
                preferredLanguage: data.preferredLanguage,
                knownLanguages: data.knownLanguages,
              },
            },
            {
              id: "education",
              name: "Education",
              visibility: "Platform-only",
              data: [
                {
                  institution: "ABC University", // Replace with real data
                  degree: "Bachelor of Science in Computer Science", // Replace with real data
                  graduationYear: 2020,
                },
              ],
            },
            {
              id: "professional",
              name: "Professional Details",
              visibility: "Private",
              data: {
                role: "Software Engineer", // Replace with real data
                experience: [
                  {
                    company: "Tech Corp", // Replace with real data
                    role: "Junior Developer", // Replace with real data
                    years: 2,
                  },
                ],
                skills: ["React", "Node.js", "JavaScript"], // Replace with real data
              },
            },
          ];
          setProfileData(sections);
          setUserImage(data.image);
          setUserName(`${data.firstname} ${data.lastname}`);
          setProfileCompletion(100)
        } else {
          console.error("Error fetching user data");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    fetchUserData();
  }, [userImage]);

  

  const [editingSection, setEditingSection] = useState<string | null>(null);
  console.log(editingSection)
  // const [profileCompletion, setProfileCompletion] = useState(60);

  const handleVisibilityChange = (sectionId: string, visibility: string) => {
    setProfileData((prevData) =>
      prevData.map((section) =>
        section.id === sectionId
          ? { ...section, visibility: visibility as ProfileSection["visibility"] }
          : section
      )
    );
  };

  const renderSectionContent = (section: ProfileSection) => {
    if (section.id === "basic") {
      const { name, email, dob, preferredLanguage, knownLanguages } = section.data;
      return (
        <>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Email:</strong> {email}</p>
          <p><strong>Date of Birth:</strong> {dob}</p>
          <p><strong>Preferred Language:</strong> {preferredLanguage}</p>
          <p><strong>Known Languages:</strong> {knownLanguages.join(", ")}</p>
        </>
      );
    }

    if (section.id === "education") {
      return section.data.map((edu: any, index: number) => (
        <div key={index}>
          <p><strong>Institution:</strong> {edu.institution}</p>
          <p><strong>Degree:</strong> {edu.degree}</p>
          <p><strong>Graduation Year:</strong> {edu.graduationYear}</p>
        </div>
      ));
    }

    if (section.id === "professional") {
      const { role, experience, skills } = section.data;
      return (
        <>
          <p><strong>Current Role:</strong> {role}</p>
          <p><strong>Skills:</strong> {skills.join(", ")}</p>
          {experience.map((exp: any, index: number) => (
            <div key={index}>
              <p><strong>Company:</strong> {exp.company}</p>
              <p><strong>Role:</strong> {exp.role}</p>
              <p><strong>Years:</strong> {exp.years}</p>
            </div>
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <div className={styles.profile}>
              <Navbar />

      {/* Header */}
      <div className={styles.header}>
        <div className={styles.profilePicture}>
          <img src="/default-avatar.png" alt="Profile" />
          <button>Edit</button>
        </div>
        <h2>{userName}</h2>
        <div className={styles.completionBar}>
          <div
            className={styles.progress}
            style={{ width: `${profileCompletion}%` }}
          ></div>
        </div>
        <p>Profile Completion: {profileCompletion}%</p>
      </div>

      {/* Sections */}
      <div className={styles.sections}>
        {profileData.map((section) => (
          <div className={styles.sectionCard} key={section.id}>
            <div className={styles.sectionHeader}>
              <h3>{section.name}</h3>
              <select
                value={section.visibility}
                onChange={(e) => handleVisibilityChange(section.id, e.target.value)}
              >
                <option value="Public">Public</option>
                <option value="Platform-only">Platform-only</option>
                <option value="Private">Private</option>
              </select>
              <button onClick={() => setEditingSection(section.id)}>Edit</button>
            </div>
            <div className={styles.sectionContent}>
              {renderSectionContent(section)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
