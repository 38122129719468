import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaTachometerAlt,
  FaBullseye,
  FaChalkboardTeacher,
  FaCalendarAlt,
  FaBell,
  FaStar,
  FaBookOpen,
  FaQuestionCircle,
} from "react-icons/fa";
import styles from "./LearnSidebar.module.css";

const LearnSidebar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { icon: <FaTachometerAlt />, label: "Dashboard", route: "/learn/dashboard" },
    { icon: <FaBullseye />, label: "Goal Management", route: "/learn/goal-management" },
    { icon: <FaChalkboardTeacher />, label: "Learning", route: "/learn/learning" },
    { icon: <FaCalendarAlt />, label: "Session Booking", route: "/learn/session-booking" },
    { icon: <FaBell />, label: "Notifications", route: "/learn/notifications" },
    // { icon: <FaLaptopCode />, label: "Practice", route: "/learn/practice" },
    { icon: <FaStar />, label: "Feedback", route: "/learn/feedback" },
    { icon: <FaBookOpen />, label: "Story", route: "/learn/story" },
    { icon: <FaQuestionCircle />, label: "FAQs", route: "/learn/faqs" },
  ];

  return (
    <div
      className={`${styles.sidebar} ${expanded ? styles.expanded : ""}`}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      
      {/* Sidebar Menu Items */}
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={`${styles.menuItem} ${
            location.pathname === item.route ? styles.active : ""
          }`}
          onClick={() => navigate(item.route)}
        >
          <span className={styles.icon}>{item.icon}</span>
          {expanded && <span className={styles.label}>{item.label}</span>}
        </div>
      ))}
    </div>
  );
};

export default LearnSidebar;
