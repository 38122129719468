import React, { useState } from "react";
import styles from "./LegalCompliance.module.css";

interface Document {
  id: number;
  name: string;
  status: "Pending" | "Signed" | "Reviewed";
}

const LegalCompliance: React.FC = () => {
  const [documents, setDocuments] = useState<Document[]>([
    { id: 1, name: "Freelance Agreement - Project Alpha", status: "Pending" },
    { id: 2, name: "NDA - Consultant Beta", status: "Signed" },
    { id: 3, name: "Employment Contract - John Doe", status: "Reviewed" },
  ]);

  const handleReview = (id: number) => {
    console.log(`Reviewing document ID: ${id}`);
    // Add logic to open the document for review
  };
  console.log(setDocuments)

  const handleSign = (id: number) => {
    console.log(`Signing document ID: ${id}`);
    // Add logic for e-signature integration
  };

  return (
    <div className={styles.legalCompliance}>
      <h2>Legal & Compliance</h2>
      <p>Ensure compliance with legal standards in your projects and hiring processes.</p>

      {/* Document List */}
      <div className={styles.documentsSection}>
        <h3>Compliance Documents</h3>
        <table className={styles.documentsTable}>
          <thead>
            <tr>
              <th>Document</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr key={doc.id}>
                <td>{doc.name}</td>
                <td className={styles[doc.status.toLowerCase()]}>{doc.status}</td>
                <td>
                  {doc.status === "Pending" && (
                    <button onClick={() => handleSign(doc.id)} className={styles.signButton}>
                      Sign
                    </button>
                  )}
                  <button onClick={() => handleReview(doc.id)} className={styles.reviewButton}>
                    Review
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add More Features */}
      <div className={styles.additionalFeatures}>
        <h3>Additional Tools</h3>
        <button className={styles.addButton}>Generate Contract</button>
        <button className={styles.addButton}>Run Background Check</button>
        <button className={styles.addButton}>Upload Compliance Policy</button>
      </div>
    </div>
  );
};

export default LegalCompliance;
