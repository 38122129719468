import React, { useState } from "react";
import styles from "./NotificationsPopup.module.css";

interface Notification {
  id: number;
  platform: "learn" | "teach" | "endeavour";
  title: string;
  description: string;
  timestamp: string;
  read: boolean;
}

const notificationsData: Notification[] = [
  { id: 1, platform: "learn", title: "Session Reminder", description: "Your Python session starts at 3 PM", timestamp: "2h ago", read: false },
  { id: 2, platform: "teach", title: "New Feedback", description: "A learner left feedback for you", timestamp: "1d ago", read: true },
  { id: 3, platform: "endeavour", title: "New Project Invite", description: "Join the AI Research project", timestamp: "3d ago", read: false },
];

const NotificationsPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [filter, setFilter] = useState<"all" | "learn" | "teach" | "endeavour">("all");

  const filteredNotifications = notificationsData.filter(
    (notif) => filter === "all" || notif.platform === filter
  );

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={styles.popup}
        onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing the popup
      >
        <div className={styles.header}>
          <h3>Notifications</h3>
          <button onClick={onClose} className={styles.closeButton}>
            ✕
          </button>
        </div>

        <div className={styles.filters}>
          {["all", "learn", "teach", "endeavour"].map((platform) => (
            <button
              key={platform}
              className={`${styles.filterButton} ${filter === platform ? styles.activeFilter : ""}`}
              onClick={() => setFilter(platform as "all" | "learn" | "teach" | "endeavour")}
            >
              {platform === "all" ? "All" : platform.charAt(0).toUpperCase() + platform.slice(1)}
            </button>
          ))}
        </div>

        <div className={styles.notificationsList}>
          {filteredNotifications.map((notif) => (
            <div
              key={notif.id}
              className={`${styles.notification} ${notif.read ? styles.read : ""}`}
            >
              <div className={`${styles.badge} ${styles[notif.platform]}`}>
                {notif.platform.charAt(0).toUpperCase()}
              </div>
              <div className={styles.content}>
                <h4>{notif.title}</h4>
                <p>{notif.description}</p>
                <span className={styles.timestamp}>{notif.timestamp}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPopup;
