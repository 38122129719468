import React from "react";
import styles from "./Feedbacks.module.css";

// Mock Data
const topics = [
  {
    topicName: "React Basics",
    averageRating: 4.6,
    totalSessions: 45,
    milestone: { current: 45, goal: 50 },
    feedback: [
      {
        id: 1,
        date: "2024-11-25",
        time: "10:00 AM",
        comment: "Very interactive session, learned a lot!",
        category: "Positive",
      },
      {
        id: 2,
        date: "2024-11-26",
        time: "3:00 PM",
        comment: "Too fast-paced, struggled to keep up.",
        category: "Neutral",
      },
    ],
  },
  {
    topicName: "Algebra Fundamentals",
    averageRating: 4.3,
    totalSessions: 30,
    milestone: { current: 30, goal: 50 },
    feedback: [
      {
        id: 3,
        date: "2024-11-24",
        time: "2:00 PM",
        comment: "Clear explanations, loved the examples.",
        category: "Positive",
      },
      {
        id: 4,
        date: "2024-11-26",
        time: "5:00 PM",
        comment: "Could use more visual aids in sessions.",
        category: "Neutral",
      },
    ],
  },
];

const Feedbacks: React.FC = () => {
  return (
    <div className={styles.feedbacks}>
      <h2 className={styles.heading}>Feedbacks and Ratings</h2>
      <p className={styles.description}>
        View your performance by topic and track milestones for promotion.
      </p>

      {/* Topic-Level Feedback */}
      {topics.map((topic) => (
        <div key={topic.topicName} className={styles.topicSection}>
          {/* Topic Header */}
          <div className={styles.topicHeader}>
            <h3 className={styles.topicName}>{topic.topicName}</h3>
            <p className={styles.rating}>
              Average Rating: <span>{topic.averageRating} ★</span>
            </p>
          </div>

          {/* Promotion Tracker */}
          <div className={styles.promotionTracker}>
            <div className={styles.progressBar}>
              <div
                className={styles.progress}
                style={{
                  width: `${(topic.milestone.current / topic.milestone.goal) * 100}%`,
                }}
              ></div>
            </div>
            <p className={styles.milestoneText}>
              {topic.milestone.current}/{topic.milestone.goal} sessions completed for promotion
            </p>
          </div>

          {/* Feedback List */}
          <div className={styles.feedbackList}>
            {topic.feedback.map((fb) => (
              <div
                key={fb.id}
                className={`${styles.feedbackCard} ${styles[fb.category.toLowerCase()]}`}
              >
                <div className={styles.feedbackDetails}>
                  <p className={styles.date}>
                    {fb.date} at {fb.time}
                  </p>
                  <p className={styles.comment}>{fb.comment}</p>
                </div>
                <span className={styles.feedbackCategory}>{fb.category}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Feedbacks;
