import React, { useState } from "react";
import styles from "../../styles/Auth.module.css";

const PasswordRecovery: React.FC = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword ] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword ] = useState<boolean>(false)
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const validateEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleRequestReset = async () => {
    if (!email) {
      setError("Please enter valid registered email");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }
    console.log("Password reset requested for:", email);
    setError("");
    try {
      // Send OTP request to the backend API
      const response = await fetch(
        "https://totle-website-backend2.onrender.com/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure we're sending JSON data
          },
          body: JSON.stringify({ email }), // Send email as JSON
        }
      );

      if (response.ok) {
        // Check if the response status is 200-299 (success)
        console.log("OTP sent successfully");
        setStep(2); // Proceed to OTP verification step
      } else {
        const errorData = await response.json(); // If the response is not successful, parse the error message
        setError(errorData.message || "Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Failed to send OTP. Please try again later.");
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP");
      return;
    }
    console.log("OTP verified:", otp);
    setError("");
    try {
      // Send OTP verification request to the backend API
      const response = await fetch(
        "https://totle-website-backend2.onrender.com/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure we're sending JSON data
          },
          body: JSON.stringify({ email, otp }), // Send email and OTP as JSON
        }
      );

      if (response.ok) {
        // Check if the response status is 200-299 (success)
        console.log("OTP verified successfully");
        setStep(3); // Proceed to reset password step
      } else {
        const errorData = await response.json(); // If the response is not successful, parse the error message
        setError(errorData.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Failed to verify OTP. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    try {
      // Input validation
      if (!newPassword || !confirmPassword) {
        setError("Please fill in all fields");
        return;
      }

      if (newPassword !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      if (newPassword.length < 8 || !/[!@#$%^&*]/.test(newPassword)) {
        setError(
          "Password must be at least 8 characters long and include a special character"
        );
        return;
      }

      // Clear any existing errors
      setError("");

      console.log("Attempting to reset password with:", newPassword);

      // Send password reset request to the backend API using fetch
      const response = await fetch(
        "https://totle-website-backend2.onrender.com/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            newPassword,
          }),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        console.log("Password reset successful");
        alert("Password has been reset successfully!");
      } else {
        // Handle error responses
        const errorData = await response.json();
        setError(
          errorData.message ||
            "Failed to reset password. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("Failed to reset password. Please try again later.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <div className={styles.authPage}>
      <div className={styles.formContainerpr}>
        {step === 1 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Forgot Password?</h2>
              <p className={styles.subheading}>
                Enter your registered email to receive a reset link.
              </p>
            </div>
            <div className={styles.inputGroup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
              />
              {error && <p className={styles.error}>{error}</p>}
            </div>

            <button className={styles.button} onClick={handleRequestReset}>
              Send OTP
            </button>
          </div>
        )}

        {step === 2 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Verify OTP</h2>
              <p className={styles.subheading}>
                Enter the 6-digit OTP sent to your email
              </p>
            </div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={styles.input}
              />
              {error && <p className={styles.error}>{error}</p>}
            </div>
            <button className={styles.button} onClick={handleVerifyOtp}>
              Verify OTP
            </button>
          </div>
        )}

        {step === 3 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Reset Password</h2>
              <p className={styles.subheading}>
                Enter and confirm your new password
              </p>
            </div>
            <div className={styles.parentInputGroup}>

              <div className={styles.inputGroup}>
                <div className={styles.passwordInput} >
                <input
                  type={showPassword ? "🙈" : "👁️"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={styles.input}
                />
                <button type="button" onClick={togglePasswordVisibility} className="passwordToggle" > {showPassword ? '🙈' : '👁️' } </button>
                </div>
                {error && <p className={styles.error}>{error}</p>}
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.passwordInput} >
                <input
                  type={ showConfirmPassword ? "🙈" : "👁️" }
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={styles.input}
                />
                <button type="button" onClick={toggleConfirmPasswordVisibility} className="passwordToggle" > {showConfirmPassword ? '🙈' : '👁️' } </button>
                </div>
                {error && <p className={styles.error}>{error}</p>}
              </div>
            </div>
            <button className={styles.button} onClick={handleResetPassword}>
              Reset Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;
