import React, { useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styles from "./Dashboard.module.css";
import ProgressBar from "@ramonak/react-progress-bar";

// Mock Data
const skillData = {
  domain: "Python Programming",
  progress: 85,
  milestone: 80,
  comparison: "Top 10%",
};

const goals = [
  { title: "Complete Python Basics", progress: 70 },
  { title: "Finish Data Science Module", progress: 50 },
  { title: "Participate in Hackathon", progress: 40 },
];

const nextSteps = [
  "Join Advanced Python Course",
  "Participate in Data Visualization Workshop",
  "Book a mentoring session with an Expert",
];

const feedback = [
  { session: "Python Basics", feedback: "Great session! Learnt a lot.", rating: 4.5 },
  { session: "Data Science Intro", feedback: "Excellent explanation.", rating: 4.8 },
];

const upcomingSessions = [
  { title: "Data Science Basics", time: "Tomorrow, 10:00 AM" },
  { title: "Python OOP Concepts", time: "Saturday, 2:00 PM" },
];

const leaderboard = [
  { name: "John Doe", score: 1500 },
  { name: "Jane Smith", score: 1400 },
  { name: "You", score: 1350 },
];

const Dashboard: React.FC = () => {
  const [minimizedWidgets, setMinimizedWidgets] = useState<string[]>([]);

  const toggleMinimize = (widget: string) => {
    setMinimizedWidgets((prev) =>
      prev.includes(widget)
        ? prev.filter((item) => item !== widget)
        : [...prev, widget]
    );
  };

  return (
    <div className={styles.dashboard}>
      {/* Skill Chart */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Skill Chart</h3>
          <button type="button" onClick={() => toggleMinimize("skill")}>_</button>
          </div>
        {!minimizedWidgets.includes("skill") && (
          <div className={styles.skillChart}>
            <CircularProgressbarWithChildren value={skillData.progress}>
              <div className={styles.skillInfo}>
                <strong>{skillData.progress}%</strong>
                <p>{skillData.domain}</p>
              </div>
            </CircularProgressbarWithChildren>
            <div className={styles.skillComparison}>
              <p>{`Milestone: ${skillData.milestone}%`}</p>
              <p>{`You are in the ${skillData.comparison}`}</p>
            </div>
          </div>
        )}
      </div>

      {/* Goal Progress */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Goal Progress</h3>
          <button onClick={() => toggleMinimize("goals")}>_</button>
        </div>
        {!minimizedWidgets.includes("goals") && (
          <ul className={styles.goalList}>
            {goals.map((goal, index) => (
              <li key={index} className={styles.goalItem}>
                <p>{goal.title}</p>
                <ProgressBar
                  completed={goal.progress}
                  height="8px"
                  labelSize="10px"
                  bgColor="#4caf50"
                  isLabelVisible={false}
                />
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Suggested Next Steps */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Suggested Next Steps</h3>
          <button onClick={() => toggleMinimize("steps")}>_</button>
        </div>
        {!minimizedWidgets.includes("steps") && (
          <ul className={styles.nextSteps}>
            {nextSteps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
        )}
      </div>

      {/* Streaks */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Streaks</h3>
          <button onClick={() => toggleMinimize("streaks")}>_</button>
        </div>
        {!minimizedWidgets.includes("streaks") && (
          <div className={styles.streaks}>
            <p>🔥 You are on a 10-day learning streak!</p>
            <p>Keep up the great work!</p>
          </div>
        )}
      </div>

      {/* Feedback Received */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Feedback</h3>
          <button onClick={() => toggleMinimize("feedback")}>_</button>
        </div>
        {!minimizedWidgets.includes("feedback") && (
          <ul className={styles.feedbackList}>
            {feedback.map((fb, index) => (
              <li key={index} className={styles.feedbackItem}>
                <p>{fb.session}</p>
                <p>{`"${fb.feedback}"`}</p>
                <p>{`Rating: ${fb.rating} ★`}</p>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Upcoming Sessions */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Upcoming Sessions</h3>
          <button onClick={() => toggleMinimize("sessions")}>_</button>
        </div>
        {!minimizedWidgets.includes("sessions") && (
          <ul className={styles.upcomingSessions}>
            {upcomingSessions.map((session, index) => (
              <li key={index}>
                <p>{session.title}</p>
                <p>{session.time}</p>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Leaderboard */}
      <div className={styles.widget}>
        <div className={styles.widgetHeader}>
          <h3>Leaderboard</h3>
          <button onClick={() => toggleMinimize("leaderboard")}>_</button>
        </div>
        {!minimizedWidgets.includes("leaderboard") && (
          <ul className={styles.leaderboard}>
            {leaderboard.map((entry, index) => (
              <li key={index}>
                <p>
                  {index + 1}. {entry.name} - {entry.score} pts
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
