import React from "react";
import styles from "./CatalogueLayer.module.css";
import LayerProgress from "./LayerProgress"; // Import LayerProgress

interface CatalogueLayerProps {
  onTopicSelect: (topic: string) => void;
}

const catalogueData = {
  Academics: {
    School: {
      "CBSE Board": {
        "Class 12": {
          Biology: ["Ecosystems", "Photosynthesis"],
        },
      },
    },
    College: {
      "JNTU Anantapur": {
        "G Pulla Reddy Engineering College": {
          "Bachelors in Mechanical Engineering": {
            Semester6: ["Instrumentation", "Strength of Materials"],
          },
        },
      },
    },
  },
  "Exam Prep": {
    UPSC: {
      "Ancient History": {
        "Maurya Empire": ["Tables", "War Strategies"],
      },
    },
  },
  Skills: {
    Technical: {
      "Front End Development": {
        HTML: ["Basics", "Advanced Techniques"],
      },
    },
    "Non-Technical": {
      Leadership: {
        "Time Management": ["Prioritization", "Scheduling"],
      },
    },
  },
  Arts: {
    Dance: {
      "Classical Dance": {
        Beginner: ["Bharatanatyam", "Kathak"],
      },
    },
  },
};

const CatalogueLayer: React.FC<CatalogueLayerProps> = ({ onTopicSelect }) => {
  const [currentLayer, setCurrentLayer] = React.useState<any>(catalogueData);
  const [path, setPath] = React.useState<string[]>([]);

  const handleCardClick = (key: string) => {
    if (typeof currentLayer[key] === "object") {
      setPath((prev) => [...prev, key]);
      setCurrentLayer(currentLayer[key]);
    } else {
      onTopicSelect(key);
    }
  };

  const handleGoBack = () => {
    const newPath = [...path];
    newPath.pop();
    let newLayer = catalogueData;
    newPath.forEach((step) => {
      newLayer = newLayer[step];
    });
    setPath(newPath);
    setCurrentLayer(newLayer);
  };

  return (
    <div className={styles.catalogueLayer}>
      <div className={styles.header}>
        <h2>Catalogue</h2>
        <LayerProgress currentLayer={path.length + 1} /> {/* Add LayerProgress */}
        {path.length > 0 && (
          <button onClick={handleGoBack} className={styles.backButton}>
            Go Back
          </button>
        )}
      </div>
      <div className={styles.cardGrid}>
        {Object.keys(currentLayer).map((key) => (
          <div
            key={key}
            className={styles.card}
            onClick={() => handleCardClick(key)}
          >
            <div className={styles.cardFront}>
              <h3>{key}</h3>
            </div>
            <div className={styles.cardBack}>
              <p>{`Explore ${key}`}</p>
              <button className={styles.enterButton}>Enter</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogueLayer;
