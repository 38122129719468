import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './LandingPage.module.css';
import { FiInfo, FiBriefcase, FiMail, FiHome } from 'react-icons/fi'; // Feather Icons

import backgroundImg from '../../images/background.jpg'; // Adjust path based on your file structure

const LandingPage: React.FC = () => {
  const [activeContent, setActiveContent] = useState<string | null>(null);

  const contentMap: Record<string, JSX.Element> = {
    home: <h1 className={styles.title}>TOTLE</h1>,
    about: (
      <div className={styles.section}>
        <h2>About Us</h2>
        <p>
          TOTLE stands for "To Teach, Learn, and Endeavor." Our mission is to
          empower educators and learners by creating a platform that combines
          affordability, accessibility, and innovation. We believe in fostering
          a community-driven learning experience where everyone has the
          opportunity to grow and contribute to knowledge sharing.
        </p>
        <p>
          Whether you're mastering exam prep, honing a skill, or exploring
          artistic passions, TOTLE connects you to experts who can help you
          achieve your goals. Join us in redefining education and making it
          accessible for all.
        </p>
      </div>
    ),
    careers: (
      <div className={styles.section}>
        <h2>Careers</h2>
        <p>
          Join TOTLE and be part of a team dedicated to revolutionizing
          education! We're looking for talented individuals who are passionate
          about creating innovative solutions. Whether you're a developer,
          designer, educator, or strategist, there's a place for you at TOTLE.
        </p>
        <p>Current Openings:</p>
        <ul>
          <li>Frontend Developers (React + TypeScript)</li>
          <li>AI/ML Engineers for Cognitive Analytics</li>
          <li>Content Strategists for Enriching Learning Experiences</li>
          <li>DevOps Engineers for Scalable Solutions</li>
        </ul>
        <p>Send your applications to careers@totle.com!</p>
      </div>
    ),
    contact: (
      <div className={styles.section}>
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Reach out to us through the form below or contact us via email or phone.</p>
        <p>Email: <a href="mailto:support@totle.com">support@totle.com</a></p>
        <p>Phone: +91- 9381281438</p>
        <form className={styles.contactForm}>
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    ),
  };

  const insertLanguages = async () => {
    try {
      // const response = await fetch('http://localhost:5000/insert-languages'
      const response = await fetch('https://totle-website-backend2.onrender.com/insert-languages'
        , {
        method: 'GET', // or 'POST' depending on your backend setup
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to insert languages');
      }

    } catch (error) {
      console.error('Error inserting languages:', error);
    }
  };

  // Use effect to trigger language insertion when landing page is loaded (optional)
  useEffect(() => {
    insertLanguages(); // Optionally trigger on page load
  }, []);


  return (
    <div className={styles.landingPage}>
      {/* Background */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.5)',
        }}
      ></div>

      {/* Navbar */}
      <nav className={styles.navbar}>
        <div className={styles.navActions}>
          <Link to="/login" className={`${styles.navButton} ${styles.login}`} onClick={insertLanguages}>
            Login
          </Link>
          <Link to="/signup" className={`${styles.navButton} ${styles.signup}`} onClick={insertLanguages}>
            Sign Up
          </Link>
        </div>
      </nav>

      {/* Sidebar */}
      <aside className={styles.sidebar}>
        <div className={styles.sidebarItem} onClick={() => setActiveContent(null)}>
          <FiHome className={styles.sidebarIcon} />
          <span className={styles.sidebarLabel}>Home</span>
        </div>
        <div className={styles.sidebarItem} onClick={() => setActiveContent('about')}>
          <FiInfo className={styles.sidebarIcon} />
          <span className={styles.sidebarLabel}>About Us</span>
        </div>
        <div className={styles.sidebarItem} onClick={() => setActiveContent('careers')}>
          <FiBriefcase className={styles.sidebarIcon} />
          <span className={styles.sidebarLabel}>Careers</span>
        </div>
        <div className={styles.sidebarItem} onClick={() => setActiveContent('contact')}>
          <FiMail className={styles.sidebarIcon} />
          <span className={styles.sidebarLabel}>Contact</span>
        </div>
      </aside>

      {/* Center Content */}
      <div
        className={
          activeContent === null
            ? `${styles.centerContent} ${styles.centerTitle}`
            : styles.centerContent
        }
      >
        {activeContent ? contentMap[activeContent] : contentMap['home']}
      </div>
    </div>
  );
};

export default LandingPage;
