import React, { useState } from "react";
import styles from "./TestPlatform.module.css";

// Mock Data
const catalogueHierarchy = {
  Academics: {
    Mathematics: ["Algebra", "Geometry", "Calculus"],
    Physics: ["Mechanics", "Optics"],
  },
  "Exam Prep": {
    GRE: ["Quantitative", "Verbal"],
    GMAT: ["Quantitative", "Analytical Writing"],
  },
  Skills: {
    "Technical Skills": ["React", "Python"],
    "Soft Skills": ["Communication", "Leadership"],
  },
  Arts: {
    Painting: ["Oil Painting", "Watercolor"],
    Music: ["Classical", "Jazz"],
  },
};

const TestPlatform: React.FC = () => {
  const [currentLevel, setCurrentLevel] = useState<string[]>([]); // Tracks current navigation path
  const [options, setOptions] = useState<string[]>(Object.keys(catalogueHierarchy));
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [topicDetails, setTopicDetails] = useState<null | {
    role: string;
    sessionsTaken: number;
    feedbackScore: number;
  }>(null);

  // Mock API call to fetch topic details
  const fetchTopicDetails = async (topic: string) => {
    return {
      role: "Learner", // Mock role
      sessionsTaken: 5,
      feedbackScore: 4.5,
    };
  };

  const handleOptionClick = async (option: string) => {
    if (selectedTopic) return;

    // Navigate deeper into the hierarchy or fetch topic details
    const nextLevel = getNextLevel(option);
    if (nextLevel) {
      setCurrentLevel((prev) => [...prev, option]);
      setOptions(nextLevel);
    } else {
      setSelectedTopic(option);
      const details = await fetchTopicDetails(option);
      setTopicDetails(details);
    }
  };

  const handleBackClick = () => {
    if (selectedTopic) {
      setSelectedTopic(null);
      setTopicDetails(null);
    } else if (currentLevel.length > 0) {
      const newLevel = [...currentLevel];
      newLevel.pop();
      setCurrentLevel(newLevel);
      setOptions(getNextLevel(newLevel[newLevel.length - 1]) || Object.keys(catalogueHierarchy));
    }
  };

  const getNextLevel = (key: string | undefined): string[] | null => {
    if (!key) return Object.keys(catalogueHierarchy);
    let level = catalogueHierarchy as any;
    for (const step of currentLevel) {
      level = level[step];
    }
    return typeof level === "object" ? Object.keys(level[key]) : null;
  };

  return (
    <div className={styles.testPlatform}>
      <h2>Test Platform</h2>

      {/* Back Button */}
      {(currentLevel.length > 0 || selectedTopic) && (
        <button className={styles.backButton} onClick={handleBackClick}>
          Back
        </button>
      )}

      {/* Navigation Options */}
      {!selectedTopic && (
        <div className={styles.cardContainer}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.card}
              onClick={() => handleOptionClick(option)}
            >
              <h3>{option}</h3>
            </div>
          ))}
        </div>
      )}

      {/* Topic Details */}
      {selectedTopic && topicDetails && (
        <div className={styles.topicDetails}>
          <h3>{selectedTopic}</h3>
          <p>Your Role: {topicDetails.role}</p>
          <p>Sessions Taken: {topicDetails.sessionsTaken}</p>
          <p>Feedback Score: {topicDetails.feedbackScore}</p>
          {topicDetails.role === "Learner" && (
            <button className={styles.startTestButton}>Take Test</button>
          )}
        </div>
      )}
    </div>
  );
};

export default TestPlatform;
