import React from "react";
import { Routes, Route } from "react-router-dom";
import TeachPlatform from "../../components/TeachPlatform/TeachPlatform";
import Dashboard from "../../components/TeachPlatform/Dashboard/Dashboard";
import Teaching from "../../components/TeachPlatform/Teaching/Teaching";
import TestPlatform from "../../components/TeachPlatform/TestPlatform/TestPlatform";
import SessionManagement from "../../components/TeachPlatform/SessionManagement/SessionManagement";
import Resources from "../../components/TeachPlatform/Resources/Resources";
import Feedbacks from "../../components/TeachPlatform/Feedbacks/Feedbacks";
import Notifications from "../../components/TeachPlatform/Notifications/Notifications";
import EarningsAnalytics from "../../components/TeachPlatform/EarningsAnalytics/EarningsAnalytics";
import ContentCreation from "../../components/TeachPlatform/ContentCreation/ContentCreation";
import Story from "../../components/TeachPlatform/Story/Story";

const TeachPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<TeachPlatform />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="teaching" element={<Teaching />} />
        <Route path="test-platform" element={<TestPlatform />} />
        <Route path="session-management" element={<SessionManagement />} />
        <Route path="resources" element={<Resources />} />
        <Route path="feedbacks" element={<Feedbacks />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="earnings" element={<EarningsAnalytics />} />
        <Route path="content" element={<ContentCreation />} />
        <Route path="story" element={<Story />} />
      </Route>
    </Routes>
  );
};

export default TeachPage;
