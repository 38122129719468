import React, { useState } from "react";
import styles from "./Competitions.module.css";

interface Competition {
  id: number;
  name: string;
  deadline: string;
  category: string;
  prize: string;
  difficulty: "Beginner" | "Intermediate" | "Advanced";
}

const Competitions: React.FC = () => {
  const [activeCompetitions, setActiveCompetitions] = useState<Competition[]>([
    { id: 1, name: "Hackathon 2024", deadline: "2024-02-20", category: "Tech", prize: "$5000", difficulty: "Advanced" },
    { id: 2, name: "UI/UX Design Challenge", deadline: "2024-03-10", category: "Design", prize: "$2000", difficulty: "Intermediate" },
    { id: 3, name: "Data Science Contest", deadline: "2024-04-05", category: "Analytics", prize: "$3000", difficulty: "Beginner" },
  ]);

  const [history, setHistory] = useState<Competition[]>([
    { id: 4, name: "AI CodeFest 2023", deadline: "2023-11-15", category: "Tech", prize: "$4000", difficulty: "Advanced" },
  ]);

  const [filters, setFilters] = useState({
    difficulty: "All",
    category: "All",
  });
  console.log(setActiveCompetitions,setHistory)

  const handleFilterChange = (key: string, value: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className={styles.competitions}>
      <h2>Competitions</h2>
      <p>Participate in various competitions and showcase your skills.</p>

      {/* Filters */}
      <div className={styles.filters}>
        <select
          onChange={(e) => handleFilterChange("difficulty", e.target.value)}
          value={filters.difficulty}
        >
          <option value="All">All Difficulties</option>
          <option value="Beginner">Beginner</option>
          <option value="Intermediate">Intermediate</option>
          <option value="Advanced">Advanced</option>
        </select>
        <select
          onChange={(e) => handleFilterChange("category", e.target.value)}
          value={filters.category}
        >
          <option value="All">All Categories</option>
          <option value="Tech">Tech</option>
          <option value="Design">Design</option>
          <option value="Analytics">Analytics</option>
        </select>
      </div>

      {/* Active Competitions */}
      <div className={styles.activeCompetitions}>
        <h3>Active Competitions</h3>
        <div className={styles.competitionList}>
          {activeCompetitions
            .filter(
              (comp) =>
                (filters.difficulty === "All" ||
                  comp.difficulty === filters.difficulty) &&
                (filters.category === "All" || comp.category === filters.category)
            )
            .map((comp) => (
              <div key={comp.id} className={styles.competitionCard}>
                <h4>{comp.name}</h4>
                <p>Category: {comp.category}</p>
                <p>Prize: {comp.prize}</p>
                <p>Deadline: {comp.deadline}</p>
                <button className={styles.participateButton}>Participate Now</button>
              </div>
            ))}
        </div>
      </div>

      {/* Competition History */}
      <div className={styles.history}>
        <h3>Past Competitions</h3>
        <div className={styles.competitionList}>
          {history.map((comp) => (
            <div key={comp.id} className={styles.competitionCard}>
              <h4>{comp.name}</h4>
              <p>Category: {comp.category}</p>
              <p>Prize: {comp.prize}</p>
              <p>Completed on: {comp.deadline}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Competitions;
