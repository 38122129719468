import React from "react";
import {  Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginForm from './components/Auth/LoginForm';
import SignupForm from './components/Auth/SignupForm';
import NotFoundPage from "../src/pages/NotFoundPage"; // New NotFoundPage for undefined routes
import PasswordRecovery from "./components/Auth/PasswordRecovery";
import PlatformPage from "./pages/PlatformPage/PlatformPage";
import MarketplacePage from "./pages/marketplace/MarketplacePage";
import TeachPage from "./pages/TeachingPlatform/TeachPage";
import LearnPage from "./pages/LearnPlatform/LearnPage";
import EndeavourPage from "./pages/EndeavourPlatform/EndeavourPage";
import Profile from "./components/Profile/Profile";

function App() {
  return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path='/forgot-password' element={<PasswordRecovery />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/platform" element={<PlatformPage />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/teach/*" element={<TeachPage />} />
        <Route path="/learn/*" element={<LearnPage />} />
        <Route path="/endeavour/*" element={<EndeavourPage />} />
      </Routes>
  );
}

export default App;
