import React from "react";
import styles from "./Notifications.module.css";

const Notifications: React.FC = () => {
  // Mock Notifications Data
  const notifications = [
    {
      id: 1,
      type: "Payment",
      title: "Payment Processed",
      message: "Your payment of $250 has been processed successfully.",
      time: "2 hours ago",
    },
    {
      id: 2,
      type: "Class Reminder",
      title: "Upcoming Class",
      message: "React Basics class starts at 10:00 AM. Don't miss it!",
      time: "1 day ago",
    },
    {
      id: 3,
      type: "Achievement",
      title: "Badge Unlocked: High Rating",
      message: "You maintained a 4.8+ rating this week. Great job!",
      time: "3 days ago",
    },
    {
      id: 4,
      type: "Challenge",
      title: "Weekly Challenge",
      message: "Teach 5 more sessions this week to earn a new badge.",
      time: "5 days ago",
    },
    {
      id: 5,
      type: "Performance",
      title: "Performance Update",
      message: "Your average rating improved by 0.2 this week.",
      time: "1 week ago",
    },
  ];

  return (
    <div className={styles.notifications}>
      <h2 className={styles.heading}>Notifications</h2>

      {/* Notification List */}
      <div className={styles.notificationList}>
        {notifications.map((notification) => (
          <div key={notification.id} className={`${styles.card} ${styles[notification.type.toLowerCase()]}`}>
            <div className={styles.cardHeader}>
              <h3>{notification.title}</h3>
              <span className={styles.time}>{notification.time}</span>
            </div>
            <p className={styles.message}>{notification.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
