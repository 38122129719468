import React, { useState } from "react";
import styles from "./Story.module.css";
import { motion } from "framer-motion";

const Story: React.FC = () => {
  const [focusedMilestone, setFocusedMilestone] = useState<number | null>(null);

  const milestones = [
    { id: 1, title: "Joined the Platform", date: "2023-01-15" },
    { id: 2, title: "Completed First Goal", date: "2023-02-01" },
    { id: 3, title: "Top 10% Learner", date: "2023-04-10" },
    { id: 4, title: "5-Day Streak", date: "2023-05-05" },
    { id: 5, title: "Overcame a Tough Challenge", date: "2023-07-12" },
  ];

  const achievements = [
    { id: 1, title: "5-Day Streak", icon: "🔥", description: "Kept learning for 5 consecutive days!" },
    { id: 2, title: "Top Performer", icon: "🏆", description: "Ranked in the top 10% of learners." },
  ];

  const challenges = [
    { id: 1, title: "Missed Goal: Python Basics", description: "Reattempted and achieved it later!" },
  ];

  return (
    <div className={styles.story}>
      {/* Header */}
      <div className={styles.header}>
        <h2>Your Learning Journey</h2>
        <button className={styles.shareButton}>
          Share Your Story
        </button>
      </div>

      {/* Animated Timeline */}
      <div className={styles.timeline}>
        {milestones.map((milestone, index) => (
          <motion.div
            key={milestone.id}
            className={styles.milestone}
            onClick={() => setFocusedMilestone(index)}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <div className={styles.milestoneNode}>
              {focusedMilestone === index ? (
                <motion.div
                  className={styles.milestoneDetails}
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                >
                  <h3>{milestone.title}</h3>
                  <p>{milestone.date}</p>
                </motion.div>
              ) : (
                <span className={styles.milestoneLabel}>
                  {milestone.title}
                </span>
              )}
            </div>
          </motion.div>
        ))}
      </div>

      {/* Achievements & Challenges */}
      <div className={styles.achievementsSection}>
        <div className={styles.achievements}>
          <h3>Achievements</h3>
          <div className={styles.cards}>
            {achievements.map((achievement) => (
              <motion.div
                key={achievement.id}
                className={styles.card}
                whileHover={{ scale: 1.1 }}
              >
                <div className={styles.cardFront}>
                  <span className={styles.icon}>{achievement.icon}</span>
                  <h4>{achievement.title}</h4>
                </div>
                <div className={styles.cardBack}>
                  <p>{achievement.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <div className={styles.challenges}>
          <h3>Challenges</h3>
          <ul>
            {challenges.map((challenge) => (
              <li key={challenge.id}>
                <h4>{challenge.title}</h4>
                <p>{challenge.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Leaderboard */}
      <motion.div
        className={styles.leaderboard}
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
      >
        <h3>Leaderboard</h3>
        <p>Rank: <strong>12th</strong></p>
        <p>Score: <strong>1450</strong></p>
      </motion.div>
    </div>
  );
};

export default Story;
