import React from "react";
import FiltersAndSearchBar from "../../components/marketplace/FiltersAndSearchBar";
import PersonalizedRecommendations from "../../components/marketplace/PersonalizedRecommendations";
import FilterResults from "../../components/marketplace/FilterResults";
import styles from "./MarketplacePage.module.css";
import Navbar from "../../components/Navbar/Navbar"; // Import the new Navbar component

const MarketplacePage: React.FC = () => {
  return (
    <div className={styles.marketplacePage}>
            <Navbar />

      {/* Page Header */}
      <header className={styles.header}>
        <h1 className={styles.title}>Marketplace</h1>
        <p className={styles.subtitle}>
          Discover insights, resources, and tailored recommendations.
        </p>
      </header>

      {/* Main Content Grid */}
      <div className={styles.gridContainer}>
        {/* Filters Section */}
        <div className={styles.filters}>
          <FiltersAndSearchBar />
        </div>

        {/* Results Section */}
        <div className={styles.results}>
          <FilterResults />
        </div>

        {/* Recommendations Section */}
        <div className={styles.recommendations}>
          <PersonalizedRecommendations />
        </div>
      </div>
    </div>
  );
};

export default MarketplacePage;
