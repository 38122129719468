import React, { useState, useEffect } from "react";
import styles from "./Notifications.module.css";

interface Notification {
  id: number;
  type: "Reminder" | "Achievement" | "Promotion";
  title: string;
  message: string;
  timestamp: string;
  isRead: boolean;
  action?: () => void;
}

const initialNotifications: Notification[] = [
  {
    id: 1,
    type: "Reminder",
    title: "Upcoming Session: Data Science Basics",
    message: "Your session starts at 4:00 PM.",
    timestamp: "10 mins ago",
    isRead: false,
    action: () => alert("Joining session..."),
  },
  {
    id: 2,
    type: "Achievement",
    title: "Congratulations!",
    message: "You completed 5 sessions this week.",
    timestamp: "1 hour ago",
    isRead: false,
  },
  {
    id: 3,
    type: "Promotion",
    title: "New Premium Offer",
    message: "Upgrade to Legend level with 20% off!",
    timestamp: "2 hours ago",
    isRead: false,
    action: () => alert("Explore Premium Plans..."),
  },
];

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>(
    initialNotifications
  );
  const [unreadCount, setUnreadCount] = useState(
    initialNotifications.filter((n) => !n.isRead).length
  );

  useEffect(() => {
    setUnreadCount(notifications.filter((n) => !n.isRead).length);
  }, [notifications]);

  const handleMarkAsRead = (id: number) => {
    setNotifications((prev) =>
      prev.map((n) =>
        n.id === id ? { ...n, isRead: true } : n
      )
    );
  };

  const handleDismiss = (id: number) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <div className={styles.notifications}>
      <header className={styles.header}>
        <h2>Notifications</h2>
        <span className={styles.badge}>{unreadCount}</span>
      </header>

      <div className={styles.filterSection}>
        <button>All</button>
        <button>Reminders</button>
        <button>Achievements</button>
        <button>Promotions</button>
      </div>

      <div className={styles.notificationList}>
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className={`${styles.card} ${
              notification.isRead ? styles.read : ""
            }`}
          >
            <div className={styles.cardContent}>
              <h3>{notification.title}</h3>
              <p>{notification.message}</p>
              <span className={styles.timestamp}>
                {notification.timestamp}
              </span>
            </div>
            <div className={styles.cardActions}>
              {notification.action && (
                <button onClick={notification.action}>Action</button>
              )}
              <button onClick={() => handleMarkAsRead(notification.id)}>
                Mark as Read
              </button>
              <button onClick={() => handleDismiss(notification.id)}>
                Dismiss
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
