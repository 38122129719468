import React, { useState } from "react";
import styles from "./Projects.module.css";

interface Project {
  id: number;
  name: string;
  category: string;
  difficulty: "Beginner" | "Intermediate" | "Advanced";
  deadline: string;
  description: string;
  mentors: string[];
}

const Projects: React.FC = () => {
  const [projects] = useState<Project[]>([
    {
      id: 1,
      name: "AI-Powered Chatbot",
      category: "Tech",
      difficulty: "Advanced",
      deadline: "2024-03-01",
      description: "Build an AI chatbot using NLP and machine learning.",
      mentors: ["Alice Johnson", "David Lee"],
    },
    {
      id: 2,
      name: "UI/UX Redesign for eCommerce",
      category: "Design",
      difficulty: "Intermediate",
      deadline: "2024-02-15",
      description: "Redesign an eCommerce platform for better user experience.",
      mentors: ["Sophia Brown"],
    },
  ]);

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  return (
    <div className={styles.projects}>
      <h2>Projects</h2>
      <p>Engage in hands-on projects and build real-world experience.</p>

      {selectedProject ? (
        <div className={styles.projectDetails}>
          <h3>{selectedProject.name}</h3>
          <p>Category: {selectedProject.category}</p>
          <p>Difficulty: {selectedProject.difficulty}</p>
          <p>Deadline: {selectedProject.deadline}</p>
          <p>{selectedProject.description}</p>
          <h4>Mentors:</h4>
          <ul>
            {selectedProject.mentors.map((mentor, idx) => (
              <li key={idx}>{mentor}</li>
            ))}
          </ul>
          <button
            className={styles.backButton}
            onClick={() => setSelectedProject(null)}
          >
            Back to Projects
          </button>
        </div>
      ) : (
        <div className={styles.projectList}>
          {projects.map((project) => (
            <div
              key={project.id}
              className={styles.projectCard}
              onClick={() => setSelectedProject(project)}
            >
              <h4>{project.name}</h4>
              <p>Category: {project.category}</p>
              <p>Difficulty: {project.difficulty}</p>
              <p>Deadline: {project.deadline}</p>
              <button className={styles.detailsButton}>View Details</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
