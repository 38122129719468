import React, { useState } from "react";
import styles from "./SessionManagement.module.css";

const SessionManagement: React.FC = () => {
  const [availability, setAvailability] = useState(
    Array(7)
      .fill(null)
      .map(() => Array(24).fill(false))
  );

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const handleToggleSlot = (dayIndex: number, hour: number) => {
    setAvailability((prev) =>
      prev.map((day, dIdx) =>
        dIdx === dayIndex ? day.map((slot, hIdx) => (hIdx === hour ? !slot : slot)) : day
      )
    );
  };

  const handleSaveChanges = () => {
    alert("Availability saved!");
    console.log("Updated Availability:", availability);
  };

  const handleReset = () => {
    setAvailability(Array(7).fill(null).map(() => Array(24).fill(false)));
  };

  return (
    <div className={styles.sessionManagement}>
      <h2 className={styles.heading}>Session Management</h2>
      <p className={styles.description}>
        Update your availability for the next 7 days. Learners will see your availability when booking sessions.
      </p>

      <div className={styles.schedule}>
        {daysOfWeek.map((day, dayIndex) => (
          <div key={day} className={styles.day}>
            <h3 className={styles.dayHeading}>{day}</h3>
            <div className={styles.timeSlots}>
              {Array.from({ length: 24 }, (_, hour) => (
                <div
                  key={hour}
                  className={`${styles.timeSlot} ${
                    availability[dayIndex][hour] ? styles.available : ""
                  }`}
                  onClick={() => handleToggleSlot(dayIndex, hour)}
                >
                  {`${hour}:00`}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.actions}>
        <button className={styles.saveButton} onClick={handleSaveChanges}>
          Save Changes
        </button>
        <button className={styles.resetButton} onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default SessionManagement;
