import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFoundPage.module.css";

const NotFoundPage: React.FC = () => {
  return (
    <div className={styles.notFound}>
      <h1 className={styles.title}>404 - Page Not Found</h1>
      <p className={styles.description}>
        Oops! The page you're looking for does not exist.
      </p>
      <Link to="/" className={styles.link}>
        Go Back to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
