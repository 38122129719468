import React from "react";
import styles from "./PaidPlatform.module.css";

interface PaidPlatformProps {
  topic: string;
}

const teachers = [
  {
    name: "Alice",
    level: "Expert",
    price: "$15",
    image: "/images/expert.jpg",
    specialties: ["Data Science", "Python"],
    testimonial: "Alice's insights transformed my learning experience!",
  },
  {
    name: "Bob",
    level: "Master",
    price: "$30",
    image: "/images/master.jpg",
    specialties: ["Machine Learning", "AI"],
    testimonial: "Bob's sessions are incredibly detailed and engaging.",
  },
  {
    name: "Charlie",
    level: "Legend",
    price: "$50",
    image: "/images/legend.jpg",
    specialties: ["Quantum Computing", "Advanced Mathematics"],
    testimonial: "Charlie's expertise is unmatched!",
  },
];

const PaidPlatform: React.FC<PaidPlatformProps> = ({ topic }) => {
  return (
    <div className={styles.paidPlatform}>
      {/* Educator Profiles */}
      <div className={styles.teacherGrid}>
        {teachers.map((teacher, index) => (
          <div key={index} className={styles.teacherCard}>
            <img src={teacher.image} alt={teacher.name} className={styles.profileImage} />
            <div className={styles.cardContent}>
              <h3>{teacher.name}</h3>
              <p className={styles.level}>{teacher.level}</p>
              <p className={styles.price}>{teacher.price} per session</p>
              <button className={styles.bookButton}>Book Now</button>
            </div>
            <div className={styles.cardOverlay}>
              <h4>Specialties</h4>
              <ul>
                {teacher.specialties.map((specialty, i) => (
                  <li key={i}>{specialty}</li>
                ))}
              </ul>
              <blockquote>{teacher.testimonial}</blockquote>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaidPlatform;
