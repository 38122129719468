import React, { useState } from "react";
import styles from "./ContentCreation.module.css";

const ContentCreation: React.FC = () => {
  const [isAllowedToUpload, setIsAllowedToUpload] = useState(true); // Mock state
  const [agreedToCopyright, setAgreedToCopyright] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    file: null as File | null,
    category: "",
  });
  console.log(setIsAllowedToUpload)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData((prev) => ({ ...prev, file: e.target.files![0] })); // Non-null assertion
    }
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreedToCopyright) {
      alert("You must agree to the copyright laws and policies before uploading.");
      return;
    }
    if (isAllowedToUpload) {
      console.log("Uploading content:", formData);
      alert("Content uploaded successfully!");
      // Reset form
      setFormData({ title: "", description: "", file: null, category: "" });
      setAgreedToCopyright(false);
    }
  };

  return (
    <div className={styles.contentCreation}>
      <h2>Content Creation</h2>
      {!isAllowedToUpload ? (
        <p className={styles.restrictionMessage}>
          You are currently not allowed to upload content. Please contact support for further details.
        </p>
      ) : (
        <form className={styles.uploadForm} onSubmit={handleSubmit}>
          {/* Content Title */}
          <div className={styles.formGroup}>
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter content title"
              required
            />
          </div>

          {/* Description */}
          <div className={styles.formGroup}>
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter a brief description of the content"
              rows={4}
              required
            ></textarea>
          </div>

          {/* Category Selection */}
          <div className={styles.formGroup}>
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a category</option>
              <option value="Academics">Academics</option>
              <option value="Exam Prep">Exam Prep</option>
              <option value="Skills">Skills</option>
              <option value="Arts">Arts</option>
            </select>
          </div>

          {/* File Upload */}
          <div className={styles.formGroup}>
            <label htmlFor="file">Upload File</label>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.ppt,.mp4,.avi,.mkv"
              required
            />
          </div>

          {/* Copyright Agreement */}
          <div className={styles.formGroupCheckbox}>
            <input
              type="checkbox"
              id="copyright"
              checked={agreedToCopyright}
              onChange={() => setAgreedToCopyright(!agreedToCopyright)}
              required
            />
            <label htmlFor="copyright">
              I agree to the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Copyright Laws and Policies</a>.
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className={styles.uploadButton}>
            Upload Content
          </button>
        </form>
      )}
    </div>
  );
};

export default ContentCreation;
