import React from "react";
import { Routes, Route } from "react-router-dom";
import LearnPlatform from "../../components/LearnPlatform/LearnPlatform";
import Dashboard from "../../components/LearnPlatform/Dashboard/Dashboard";
import GoalManagement from "../../components/LearnPlatform/GoalManagement/GoalManagement";
import Learning from "../../components/LearnPlatform/Learning/Learning";
import SessionBooking from "../../components/LearnPlatform/SessionBooking/SessionBooking";
import Notifications from "../../components/LearnPlatform/Notifications/Notifications";
// import Practice from "../../components/LearnPlatform/Practice/Practice";
import Feedback from "../../components/LearnPlatform/Feedback/Feedback";
import Story from "../../components/LearnPlatform/Story/Story";
import FAQs from "../../components/LearnPlatform/FAQs/FAQs";

const LearnPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LearnPlatform />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="goal-management" element={<GoalManagement />} />
        <Route path="learning" element={<Learning />} />
        <Route path="session-booking" element={<SessionBooking />} />
        <Route path="notifications" element={<Notifications />} />
        {/* <Route path="practice" element={<Practice />} /> */}
        <Route path="feedback" element={<Feedback />} />
        <Route path="story" element={<Story />} />
        <Route path="faqs" element={<FAQs />} />
      </Route>
    </Routes>
  );
};

export default LearnPage;
