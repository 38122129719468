import React, { useState } from "react";
import styles from "./GoalManagement.module.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";

interface Goal {
  id: number;
  name: string;
  category: string;
  startDate: string;
  targetDate: string;
  progress: number;
  status: "Active" | "Completed" | "Overdue";
  tracker?: Tracker;
}

interface Tracker {
  subjects: { [subject: string]: string[] }; // Topics by subject
}

const GoalManagement: React.FC = () => {
  const [goals, setGoals] = useState<Goal[]>([
    {
      id: 1,
      name: "Learn React",
      category: "Skills",
      startDate: "2023-12-01",
      targetDate: "2023-12-10",
      progress: 80,
      status: "Active",
      tracker: {
        subjects: {
          "React Basics": ["Components", "State", "Props"],
          "Advanced React": ["Hooks", "Context API", "Performance"],
        },
      },
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);
  const [activeSubject, setActiveSubject] = useState<string | null>(null);
  const [topicStatus, setTopicStatus] = useState<{ [key: string]: string }>({});

  const initializeTopicStatus = (goal: Goal) => {
    const allTopics = Object.values(goal.tracker?.subjects || {}).flat();
    const initialStatus = allTopics.reduce(
      (acc, topic) => ({ ...acc, [topic]: "Not Started" }),
      {}
    );
    setTopicStatus(initialStatus);
  };

  const handleAddEdit = (goal: Goal | null) => {
    setSelectedGoal(goal);
    if (goal) initializeTopicStatus(goal);
    setShowModal(true);
  };

  const handleDelete = (id: number) => {
    setGoals((prev) => prev.filter((goal) => goal.id !== id));
  };

  const handleColorToggle = (topic: string) => {
    setTopicStatus((prev) => {
      const nextStatus =
        prev[topic] === "Not Started"
          ? "Self-Read"
          : prev[topic] === "Self-Read"
          ? "Completed with Totle"
          : "Not Started";
      return { ...prev, [topic]: nextStatus };
    });
  };

  const chartData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Completed Goals",
        data: [3, 5, 7, 10],
        backgroundColor: "#4caf50",
      },
      {
        label: "Overdue Goals",
        data: [2, 1, 1, 0],
        backgroundColor: "#f44336",
      },
    ],
  };

  const chartOptions: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  return (
    <div className={styles.goalManagement}>
      <div className={styles.header}>
        <h2>Goal Management</h2>
        <button onClick={() => handleAddEdit(null)}>Add Goal</button>
      </div>

      <table className={styles.goalTable}>
        <thead>
          <tr>
            <th>Goal</th>
            <th>Category</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {goals.map((goal) => (
            <tr key={goal.id}>
              <td>{goal.name}</td>
              <td>{goal.category}</td>
              <td>
                <ProgressBar completed={goal.progress} height="10px" />
              </td>
              <td className={styles[goal.status.toLowerCase()]}>{goal.status}</td>
              <td>
                <button onClick={() => handleAddEdit(goal)}>Edit</button>
                <button onClick={() => handleDelete(goal.id)}>Delete</button>
                <button onClick={() => setSelectedGoal(goal)}>Tracker</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.analytics}>
        <h3>Goal Analytics</h3>
        <Bar data={chartData} options={chartOptions} />
      </div>

      {showModal && selectedGoal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Tracker for {selectedGoal.name}</h3>
            <div className={styles.tracker}>
              <div className={styles.sidebar}>
                {Object.keys(selectedGoal.tracker!.subjects).map((subject) => (
                  <button
                    key={subject}
                    onClick={() => setActiveSubject(subject)}
                    className={`${styles.tab} ${
                      activeSubject === subject ? styles.activeTab : ""
                    }`}
                  >
                    {subject}
                  </button>
                ))}
              </div>
              <div className={styles.main}>
                {activeSubject &&
                  selectedGoal.tracker!.subjects[activeSubject].map((topic) => (
                    <div
                      key={topic}
                      className={`${styles.bubble} ${
                        topicStatus[topic] === "Self-Read"
                          ? styles.selfRead
                          : topicStatus[topic] === "Completed with Totle"
                          ? styles.completed
                          : ""
                      }`}
                      onClick={() => handleColorToggle(topic)}
                    >
                      {topic}
                    </div>
                  ))}
                <div className={styles.stats}>
                  <p>Overall Progress: 70%</p>
                  <p>{activeSubject} Completion: 50%</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setSelectedGoal(null);
                setShowModal(false);
              }}
              className={styles.closeButton}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoalManagement;
