import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./Teaching.module.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Teaching: React.FC = () => {
  const [countdown, setCountdown] = useState<number>(0);
  const [sessionTime] = useState(new Date(Date.now() + 15 * 60 * 1000)); // Example: session starts in 15 mins
  const [joinEnabled, setJoinEnabled] = useState(false);

  const learnerMetrics = {
    Academics: [
      { name: "Concept Mastery", value: 85 },
      { name: "Retention", value: 70 },
    ],
    Examprep: [
      { name: "Mock Tests", value: 90 },
      { name: "Time Management", value: 60 },
    ],
    Skills: [
      { name: "Technical Mastery", value: 75 },
      { name: "Creativity", value: 80 },
    ],
    Arts: [
      { name: "Drawing Skills", value: 50 },
      { name: "Music Theory", value: 65 },
    ],
  };

  const engagementData = {
    labels: ["Concept Mastery", "Mock Tests", "Technical Mastery", "Drawing Skills"],
    datasets: [
      {
        label: "Engagement Metrics",
        data: [85, 90, 75, 50],
        backgroundColor: ["#6c5ce7", "#00cec9", "#fab1a0", "#fdcb6e"],
        borderRadius: 12,
      },
    ],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeRemaining = Math.max(sessionTime.getTime() - now.getTime(), 0);
      setCountdown(Math.ceil(timeRemaining / 1000));
      setJoinEnabled(timeRemaining <= 5 * 60 * 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [sessionTime]);

  const formatCountdown = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleJoinSession = () => {
    alert("Joining session!");
  };

  return (
    <div className={styles.teaching}>
      {/* Row 1: Session Overview */}
      <div className={styles.row}>
        <div className={styles.widget}>
          <h3>Session Overview</h3>
          <p><strong>Title:</strong> AI Basics</p>
          <p><strong>Time:</strong> {sessionTime.toLocaleTimeString()}</p>
          <div className={styles.timer}>
            <CircularProgressbarWithChildren
              value={(countdown / 1200) * 100}
              styles={buildStyles({
                trailColor: "#f0f0f0",
                pathColor: joinEnabled ? "#6c5ce7" : "#fd79a8",
              })}
            >
              <p className={styles.timerText}>
                {countdown > 0 ? formatCountdown() : "00:00"}
              </p>
            </CircularProgressbarWithChildren>
          </div>
          <button
            className={`${styles.joinButton} ${joinEnabled ? "" : styles.disabled}`}
            onClick={handleJoinSession}
            disabled={!joinEnabled}
          >
            Join Session
          </button>
        </div>
      </div>

      {/* Row 2: Learner Metrics */}
      <div className={styles.row}>
        {Object.entries(learnerMetrics).map(([category, metrics]) => (
          <div key={category} className={styles.widget}>
            <h3>{category}</h3>
            <div className={styles.metrics}>
              {metrics.map((metric) => (
                <div key={metric.name} className={styles.metricItem}>
                  <CircularProgressbarWithChildren
                    value={metric.value}
                    styles={buildStyles({
                      pathColor: "#6c5ce7",
                      trailColor: "#e0e0e0",
                    })}
                  >
                    <p className={styles.metricValue}>{metric.value}%</p>
                  </CircularProgressbarWithChildren>
                  <p className={styles.metricLabel}>{metric.name}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Row 3: Engagement Dashboard */}
      <div className={styles.row}>
        <div className={styles.widget}>
          <h3>Engagement Dashboard</h3>
          <div className={styles.graphWrapper}>
            <Bar
              data={engagementData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: { display: false },
                  tooltip: {
                    callbacks: {
                      label: (context) => `${context.raw}% engagement`,
                    },
                  },
                },
                scales: {
                  x: { ticks: { color: "#6c5ce7", font: { size: 10 } } },
                  y: { ticks: { color: "#6c5ce7", font: { size: 10 } } },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teaching;
