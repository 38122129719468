import React, { useState, FormEvent, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AxiosError } from "axios";
import styles from "../../styles/Auth.module.css";
import video from "../../assets/Login/loginBG5.mp4";

//API endpoint for user details
const loginAPI = process.env.REACT_APP_API_URL;

//Interface for login form data
interface LoginFormData {
  email: string;
  password: string;
}
// Interface for API response
interface LoginResponse {
  token: string;
  user: {
    id: number;
    email: string;
  };
}

// Interface for API error response
interface ApiError {
  message: string;
  status: number;
}

const LoginForm: React.FC = () => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  //Timer reference to handle cleanup error messages when appeard
  let timerRef = useRef<NodeJS.Timeout>();

  //Clear the timer when component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    //Clear any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    try {
      const response = await axios.post<LoginResponse>(
        `${loginAPI}/login-user`,
        formData
      );

      //Store token in local storage
      localStorage.setItem("token", response.data.token);
      //Succesful login navigate to profile page
      navigate("/profile");
    } catch (err) {
      const error = err as AxiosError<ApiError>;

      //Handle specific error cases
      if (error.response?.status === 401) {
        setError("Invalid email or password!!!");
      } else if (error.response?.status === 404) {
        setError("User not found!");
      } else {
        setError(
          error.response?.data?.message || "Failed to login. Please try again."
        );
      }
      //Set new timer to clear error popup after 4 seconds

      timerRef.current = setTimeout(() => {
        setError("");
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

 

  return (
    <div className={styles.loginPage} id={styles.loginPage}>
      <video className={styles.backgroundVideo} autoPlay loop muted playsInline>
        <source src={video} />
        Your browser does not support the video tag.
      </video>

      <div className={styles.loginChildContainer}>
        <h2>Welcome Back Tottler...</h2>
        <form onSubmit={handleLogin}>
          <div className={styles.loginMiddleContainer}>
            <div className={styles.loginForm}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />

              <div className={styles.passwordField}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className={styles.togglePasswordButton}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>
            </div>
            {error && (
              <div className={styles.errorContainer}>
                <div className={styles.loginError} role="alert">
                  {error} <span>!!!</span>
                </div>
              </div>
            )}
            <div className={styles.forgotPassword}>
              <a href="/forgot-password">Forgot Password?</a>
            </div>
          </div>
          <div className={styles.loginBtn}  >
            <button type="submit" disabled={isLoading} aria-busy={isLoading}>
              {isLoading ? "Logging in..." : "Login"}
            </button>
            <div className={styles.signupBtn}>
              <p>
                Don't have an account? <a href="/signup">Sign Up</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
