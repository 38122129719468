import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChartPie, FaTrophy, FaFolderOpen, FaBriefcase, FaBalanceScale } from "react-icons/fa"; // Importing Font Awesome Icons
import styles from "./EndeavourSidebar.module.css";

const EndeavourSidebar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { icon: <FaChartPie />, label: "Dashboard", route: "/endeavour/dashboard" },
    { icon: <FaTrophy />, label: "Competitions", route: "/endeavour/competitions" },
    { icon: <FaFolderOpen />, label: "Projects", route: "/endeavour/projects" },
    { icon: <FaBriefcase />, label: "Portfolio Building", route: "/endeavour/portfolio-building" },
    { icon: <FaBalanceScale />, label: "Legal & Compliance", route: "/endeavour/legal-compliance" },
  ];

  return (
    <div
      className={`${styles.sidebar} ${expanded ? styles.expanded : ""}`}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={styles.menuItem}
          onClick={() => navigate(item.route)}
        >
          <span className={styles.icon}>{item.icon}</span>
          {expanded && <span className={styles.label}>{item.label}</span>}
        </div>
      ))}
    </div>
  );
};

export default EndeavourSidebar;
