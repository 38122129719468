import React from "react";
import { motion } from "framer-motion";
import styles from "./Story.module.css";

// Mock Data for Milestones
const milestones = [
  {
    id: 1,
    title: "Joined the Platform",
    description: "Started your journey with ToTLE as a teacher.",
    date: "2021-01-15",
  },
  {
    id: 2,
    title: "First Bridger Session",
    description: "Conducted your first session as a Bridger.",
    date: "2021-02-10",
  },
  {
    id: 3,
    title: "Promoted to Expert",
    description: "Achieved Expert level in Mathematics.",
    date: "2021-06-20",
  },
  {
    id: 4,
    title: "Master in Physics",
    description: "Reached Master level in Physics.",
    date: "2022-03-05",
  },
  {
    id: 5,
    title: "100 Sessions Milestone",
    description: "Completed 100 sessions across various topics.",
    date: "2022-08-12",
  },
];

const Story: React.FC = () => {
  return (
    <div className={styles.story}>
      <h2 className={styles.title}>Your Teaching Journey</h2>
      <div className={styles.timeline}>
        {milestones.map((milestone, index) => (
          <motion.div
            key={milestone.id}
            className={styles.milestone}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.3 }}
          >
            <div className={styles.icon}>
              <motion.div
                className={styles.glow}
                whileHover={{ scale: 1.1, boxShadow: "0 0 20px rgba(0,0,255,0.5)" }}
              ></motion.div>
            </div>
            <div className={styles.content}>
              <h3>{milestone.title}</h3>
              <p>{milestone.description}</p>
              <span className={styles.date}>{milestone.date}</span>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Story;
