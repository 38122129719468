import React, { useState } from "react";
import AnimatedIllustration from "./AnimatedIllustration";
import styles from "./FreePlatform.module.css";

interface FreePlatformProps {
  topic: string;
}

const FreePlatform: React.FC<FreePlatformProps> = ({ topic }) => {
  const [bridger, setBridger] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleBooking = () => {
    setIsLoading(true);
    setTimeout(() => {
      setBridger("John Doe (Bridger)");
      setIsLoading(false);
    }, 3000);
  };

  return (
    <div className={styles.freePlatformContainer}>
      <h3 className={styles.header}>Free Platform: {topic}</h3>
      {!bridger && !isLoading && (
        <div className={styles.bookingSection}>
          <p className={styles.instruction}>Click below to find a Bridger for your session!</p>
          <button className={styles.scheduleButton} onClick={handleBooking}>
            Find a Bridger
          </button>
        </div>
      )}
      {isLoading && (
        <div className={styles.loadingSection}>
          <AnimatedIllustration />
          <p className={styles.loadingText}>Searching for the perfect Bridger...</p>
        </div>
      )}
      {bridger && (
        <div className={styles.confirmationSection}>
          <p className={styles.confirmationMessage}>
            🎉 Your session is booked with <strong>{bridger}</strong>!
          </p>
          <p className={styles.nextStep}>Get ready for an amazing learning experience.</p>
        </div>
      )}
    </div>
  );
};

export default FreePlatform;
