import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts"; // For the graph
import styles from "./FilterResults.module.css";

// Mock APIs (Replace with real API integration)
const fetchFilteredResults = async (filters: Record<string, string>, page: number) => {
  return {
    results: [
      {
        id: "1",
        title: "Introduction to AI",
        description: "Learn the basics of Artificial Intelligence and its applications.",
        sessions: { last6Hours: 5, last24Hours: 15, lastWeek: 50, lastMonth: 200 },
        demandData: [
          { time: "Jan", searches: 10, clicks: 20 },
          { time: "Feb", searches: 15, clicks: 30 },
          { time: "Mar", searches: 20, clicks: 40 },
        ],
        isTeacher: false,
      },
      {
        id: "2",
        title: "Mastering React",
        description: "Deep dive into building React-based applications.",
        sessions: { last6Hours: 3, last24Hours: 8, lastWeek: 25, lastMonth: 100 },
        demandData: [
          { time: "Jan", searches: 25, clicks: 50 },
          { time: "Feb", searches: 30, clicks: 60 },
          { time: "Mar", searches: 35, clicks: 70 },
        ],
        isTeacher: true,
      },
    ],
    totalPages: 2,
  };
};

const FilterResults: React.FC = () => {
  const [results, setResults] = useState<
    {
      id: string;
      title: string;
      description: string;
      sessions: { last6Hours: number; last24Hours: number; lastWeek: number; lastMonth: number };
      demandData: { time: string; searches: number; clicks: number }[];
      isTeacher: boolean;
    }[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filters] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sessionFilter, setSessionFilter] = useState<"last6Hours" | "last24Hours" | "lastWeek" | "lastMonth">("last6Hours");

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);
      const data = await fetchFilteredResults(filters, currentPage);
      setResults(data.results);
      setTotalPages(data.totalPages);
      setIsLoading(false);
    };

    fetchResults();
  }, [filters, currentPage]);

  const handlePageChange = (direction: "next" | "prev") => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Filter results based on search term
  const filteredResults = results.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Filter Results</h2>

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                {/* Topic Name with Search */}
                <th className={styles.header}>
                  Topic Name
                  <input
                    type="text"
                    placeholder="Search topics..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.searchInput}
                  />
                </th>
                {/* Session Count Filter */}
                <th className={styles.header}>
                  Sessions
                  <select
                    value={sessionFilter}
                    onChange={(e) => setSessionFilter(e.target.value as any)}
                    className={styles.filterDropdown}
                  >
                    <option value="last6Hours">Last 6 Hours</option>
                    <option value="last24Hours">Last 24 Hours</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="lastMonth">Last Month</option>
                  </select>
                </th>
                {/* Description */}
                <th className={styles.header}>Description</th>
                {/* Demand Graph */}
                <th className={styles.header}>Demand</th>
              </tr>
            </thead>
            <tbody>
              {filteredResults.map((item) => (
                <tr key={item.id} className={styles.row}>
                  <td>{item.title}</td>
                  <td>{item.sessions[sessionFilter]}</td>
                  <td>{item.description}</td>
                  <td>
                    <ResponsiveContainer width={100} height={50}>
                      <LineChart data={item.demandData}>
                        <XAxis dataKey="time" hide />
                        <YAxis hide />
                        <Tooltip />
                        <Line type="monotone" dataKey="searches" stroke="#8884d8" />
                        <Line type="monotone" dataKey="clicks" stroke="#82ca9d" />
                      </LineChart>
                    </ResponsiveContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className={styles.pagination}>
        <button
          className={styles.pageButton}
          onClick={() => handlePageChange("prev")}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={styles.pageButton}
          onClick={() => handlePageChange("next")}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default FilterResults;
