import React, { useEffect, useState } from "react";
import styles from "./PersonalizedRecommendations.module.css";

// Mock API Call (Replace with actual API integration)
const fetchPersonalizedRecommendations = async () => {
  return [
    { id: "1", title: "Introduction to AI", bookings: 120 },
    { id: "2", title: "Data Science with Python", bookings: 95 },
    { id: "3", title: "Mastering React", bookings: 150 },
    { id: "4", title: "Blockchain Basics", bookings: 80 },
    { id: "5", title: "Cybersecurity Essentials", bookings: 60 },
  ];
};

const PersonalizedRecommendations: React.FC = () => {
  const [recommendations, setRecommendations] = useState<
    { id: string; title: string; bookings: number }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendations = async () => {
      const data = await fetchPersonalizedRecommendations();
      setRecommendations(data.slice(0, 5)); // Limit to top 5 recommendations
      setIsLoading(false);
    };

    fetchRecommendations();
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Personalized Recommendations</h2>

      {/* Loading State */}
      {isLoading ? (
        <div className={styles.loadingPlaceholder}>Loading...</div>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.header}>Topic Name</th>
              <th className={styles.header}>Expected Bookings (Next 48 Hours)</th>
            </tr>
          </thead>
          <tbody>
            {recommendations.map((item) => (
              <tr key={item.id} className={styles.row}>
                <td className={styles.cell}>{item.title}</td>
                <td className={styles.cell}>{item.bookings}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PersonalizedRecommendations;
