import React, { useState } from "react";
import styles from "./FAQs.module.css";

const FAQs: React.FC = () => {
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const faqs = [
    { 
      category: "Sessions",
      questions: [
        { q: "How do I book a session?", a: "Navigate to 'Session Booking' and select a topic from the catalogue." },
        { q: "Can I reschedule a session?", a: "Yes, you can reschedule a session up to 24 hours before its start time." },
      ],
    },
    {
      category: "Practice",
      questions: [
        { q: "How do I access practice materials?", a: "Go to 'Practice' and select a topic from the catalogue to begin practicing." },
        { q: "Are practice questions personalized?", a: "Yes, they are tailor-made based on your progress and skills." },
      ],
    },
    {
      category: "Payments",
      questions: [
        { q: "What are the payment options?", a: "We accept credit cards, debit cards, and popular payment wallets." },
        { q: "Can I get a refund?", a: "Refunds are available only for unused session credits." },
      ],
    },
  ];

  const handleToggle = (question: string) => {
    setExpandedQuestion((prev) => (prev === question ? null : question));
  };

  const filteredFAQs = faqs.map((category) => ({
    ...category,
    questions: category.questions.filter((q) =>
      q.q.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  })).filter(category => category.questions.length > 0);

  return (
    <div className={styles.faqs}>
      <h2>FAQs</h2>

      {/* Search Bar */}
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search FAQs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* FAQ Sections */}
      {filteredFAQs.length === 0 ? (
        <p className={styles.noResults}>No results found for "{searchQuery}".</p>
      ) : (
        filteredFAQs.map((category, index) => (
          <div key={index} className={styles.category}>
            <h3>{category.category}</h3>
            {category.questions.map((q, idx) => (
              <div key={idx} className={styles.question}>
                <div
                  className={styles.questionHeader}
                  onClick={() => handleToggle(q.q)}
                >
                  <p>{q.q}</p>
                  <span>{expandedQuestion === q.q ? "−" : "+"}</span>
                </div>
                {expandedQuestion === q.q && (
                  <div className={styles.answer}>{q.a}</div>
                )}
              </div>
            ))}
          </div>
        ))
      )}

      {/* Contact Support */}
      <div className={styles.contactSupport}>
        <p>Still have questions? <a href="/support">Contact Support</a></p>
      </div>
    </div>
  );
};

export default FAQs;
