import React, { useState, useEffect } from "react";
import styles from "./Learning.module.css";
import { FaExpand, FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Learning: React.FC = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(7200); // Timer (seconds) for 2 hours
  const [sessionEnded, setSessionEnded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prev) => prev - 1);
      } else if (timeLeft === 0) {
        setSessionEnded(true); // Enable the test button when session ends
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours > 0 ? `${hours}:` : ""}${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className={`${styles.learning} ${isFullscreen ? styles.fullscreen : ""}`}>
      <div className={styles.header}>
        <h2>Learning Session</h2>
        <div className={styles.headerActions}>
          <button
            className={styles.actionButton}
            onClick={() => window.open("/learn/video-call", "_blank")}
          >
            <FaExternalLinkAlt /> Open in New Window
          </button>
          <button className={styles.actionButton} onClick={toggleFullscreen}>
            <FaExpand /> {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
          </button>
        </div>
      </div>

      <div className={styles.content}>
        {/* Session Details */}
        <div className={styles.sessionDetails}>
          <h3>Session Details</h3>
          <p><strong>Topic:</strong> Python Basics</p>
          <p><strong>Educator:</strong> John Doe</p>
          <p><strong>Start Time:</strong> Today at 2:00 PM</p>
          <p><strong>Duration:</strong> 2 hours</p>
          <div className={styles.timer}>
            {sessionEnded ? (
              <p>Session has ended. You may now take the test.</p>
            ) : (
              <p>Time left: {formatTime(timeLeft)}</p>
            )}
          </div>
        </div>

        {/* Session Resources */}
        <div className={styles.resources}>
          <h3>Session Resources</h3>
          <ul>
            <li><a href="/basics">Topic Notes: Python Basics</a></li>
            <li><a href="/advance">Audio Version: Python Basics</a></li>
            <li><a href="/video">Video Version: Python Basics</a></li>
          </ul>
        </div>

        {/* Follow-Up Test */}
        <div className={styles.test}>
          <h3>Follow-Up Test</h3>
          <p>Complete the test to evaluate your understanding of this session.</p>
          <button
            className={styles.testButton}
            onClick={() => navigate("/learn/follow-up-test")}
            disabled={!sessionEnded}
          >
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default Learning;
