import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.css";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  // Mock data
  const recommendations = ["React Basics", "Advanced JavaScript", "Python for Beginners"];
  const activityFeed = [
    "Completed a session on React Basics",
    "Received 5-star feedback",
    "Created a new course on AI",
  ];
  const analyticsData = { totalSessions: 120, totalStudents: 300, avgRating: 4.8 };
  const personalizedTips = [
    "Focus on trending topics like AI",
    "Create bite-sized courses",
    "Engage with students post-session",
  ];

  return (
    <div className={styles.dashboard}>
      <div
        className={styles.widget}
        onClick={() => navigate("/teach/test-platform")}
        role="button"
        tabIndex={0}
      >
        <h3>Recommendations</h3>
        <ul>
          {recommendations.map((rec, index) => (
            <li key={index}>{rec}</li>
          ))}
        </ul>
      </div>

      <div
        className={styles.widget}
        onClick={() => navigate("/teach/earnings")}
        role="button"
        tabIndex={0}
      >
        <h3>Earnings So Far</h3>
        <p>$2,450</p>
      </div>

      <div className={styles.widget}>
        <h3>Upcoming Milestones</h3>
        <p>5 sessions left to upgrade to Expert in React Basics</p>
      </div>

      <div
        className={styles.widget}
        onClick={() => navigate("/teach/teaching")}
        role="button"
        tabIndex={0}
      >
        <h3>Scheduled Sessions Today</h3>
        <p>3 Sessions</p>
      </div>

      <div className={styles.widget}>
        <h3>Overall Ratings</h3>
        <p>4.8 ★</p>
      </div>

      <div className={styles.widget}>
        <h3>Activity Feed</h3>
        <ul>
          {activityFeed.map((activity, index) => (
            <li key={index}>{activity}</li>
          ))}
        </ul>
      </div>

      <div className={styles.widget}>
        <h3>Analytics</h3>
        <p>Total Sessions: {analyticsData.totalSessions}</p>
        <p>Total Students: {analyticsData.totalStudents}</p>
        <p>Avg Rating: {analyticsData.avgRating}</p>
      </div>

      <div className={styles.widget}>
        <h3>Personalized Tips</h3>
        <ul>
          {personalizedTips.map((tip, index) => (
            <li key={index}>{tip}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dashboard;
