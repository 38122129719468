import React, { useState } from "react";
import styles from "./Resources.module.css";

// Mock Data
const resources = [
  {
    id: 1,
    title: "Effective Classroom Management",
    type: "Article",
    thumbnail: "/path/to/article-thumb.jpg",
    topic: "General",
    description: "Learn strategies for managing classrooms effectively.",
    isAccessible: true,
  },
  {
    id: 2,
    title: "Lesson Plan: Algebra Basics",
    type: "Lesson Plan",
    thumbnail: "/path/to/lesson-plan-thumb.jpg",
    topic: "Mathematics",
    description: "Detailed lesson plan for teaching algebra.",
    isAccessible: false,
  },
  {
    id: 3,
    title: "How to Teach Coding",
    type: "Video",
    thumbnail: "/path/to/video-thumb.jpg",
    topic: "Computer Science",
    description: "Step-by-step guide on teaching coding.",
    isAccessible: true,
  },
  {
    id: 4,
    title: "Tips for Engaging Students",
    type: "Guide",
    thumbnail: "/path/to/guide-thumb.jpg",
    topic: "General",
    description: "Tips and tricks for keeping students engaged in class.",
    isAccessible: true,
  },
];

const Resources: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  const categories = ["All", "Lesson Plan", "Video", "Article", "Guide"];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  const filteredResources = resources.filter(
    (resource) =>
      (selectedCategory === "All" || resource.type === selectedCategory) &&
      (resource.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        resource.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className={styles.resources}>
      <h2 className={styles.heading}>Teaching Resources</h2>
      <p className={styles.description}>
        Explore curated resources to enhance your teaching skills. Some resources may be restricted based on your qualifications.
      </p>

      {/* Filters and Search */}
      <div className={styles.controls}>
        <input
          type="text"
          placeholder="Search resources..."
          value={searchQuery}
          onChange={handleSearch}
          className={styles.searchBar}
        />
        <select
          value={selectedCategory}
          onChange={handleFilterChange}
          className={styles.filterDropdown}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      {/* Resource List */}
      <div className={styles.resourceList}>
        {filteredResources.map((resource) => (
          <div
            key={resource.id}
            className={`${styles.resourceCard} ${
              !resource.isAccessible ? styles.restricted : ""
            }`}
          >
            <img
              src={resource.thumbnail}
              alt={resource.title}
              className={styles.thumbnail}
            />
            <div className={styles.cardDetails}>
              <h3 className={styles.title}>{resource.title}</h3>
              <p className={styles.description}>{resource.description}</p>
              <p className={styles.type}>{resource.type}</p>
              {!resource.isAccessible && (
                <p className={styles.restrictedMessage}>
                  Restricted: Not qualified for this topic.
                </p>
              )}
              {resource.isAccessible && (
                <button className={styles.viewButton}>View Resource</button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
