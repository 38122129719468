import React, { useState } from "react";
import styles from "./Feedback.module.css";

interface FeedbackItem {
  topic: string;
  rating: number;
  comments: string[];
  date: string;
  trend: "Improved" | "Neutral" | "Needs Work";
}

const feedbackData: FeedbackItem[] = [
  {
    topic: "Time Management",
    rating: 4.5,
    comments: [
      "Great improvement in managing deadlines.",
      "Consistent performance during tasks.",
    ],
    date: "2023-12-01",
    trend: "Improved",
  },
  {
    topic: "Communication Skills",
    rating: 3.2,
    comments: [
      "Needs to express ideas more clearly.",
      "Practice active listening techniques.",
    ],
    date: "2023-11-28",
    trend: "Needs Work",
  },
];

const suggestions = [
  { icon: "📘", text: "Review time management techniques", link: "/resources" },
  { icon: "💡", text: "Join group discussions", link: "/practice" },
  { icon: "🎯", text: "Focus on active listening", link: "/resources" },
];

const Feedback: React.FC = () => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className={styles.feedback}>
      <div className={styles.summary}>
        <h2>Feedback Summary</h2>
        <div className={styles.stats}>
          <p>📊 Average Rating: 4.2</p>
          <p>🏆 Recent Achievement: Improved Time Management!</p>
        </div>
      </div>

      <div className={styles.content}>
        {/* Left Section - Suggestions */}
        <div className={styles.suggestions}>
          <h3>Actionable Suggestions</h3>
          <ul>
            {suggestions.map((suggestion, index) => (
              <li key={index} className={styles.suggestion}>
                <span className={styles.icon}>{suggestion.icon}</span>
                <p>
                  {suggestion.text} -{" "}
                  <a href={suggestion.link} className={styles.link}>
                    View
                  </a>
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* Right Section - Feedback Details */}
        <div className={styles.feedbackCards}>
          <h3>Detailed Feedback</h3>
          {feedbackData.map((item, index) => (
            <div
              key={index}
              className={`${styles.card} ${
                expanded === index ? styles.expanded : ""
              }`}
            >
              <div className={styles.cardHeader} onClick={() => toggleExpand(index)}>
                <h4>{item.topic}</h4>
                <p>{`Rating: ${item.rating}`}</p>
              </div>
              {expanded === index && (
                <div className={styles.cardBody}>
                  <p>Date: {item.date}</p>
                  <p>Trend: {item.trend}</p>
                  <ul>
                    {item.comments.map((comment, idx) => (
                      <li key={idx}>{comment}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
