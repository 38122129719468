import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./EarningsAnalytics.module.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const EarningsAnalytics: React.FC = () => {
  // Mock Data
  const totalEarnings = 3450;
  const earningsByLevel = {
    Bridger: 1200,
    Expert: 1500,
    Master: 500,
    Legend: 250,
  };
  const earningsOverTime = [200, 450, 300, 500, 600, 400, 1000]; // Weekly earnings

  // Chart Configurations
  const barData = {
    labels: ["Bridger", "Expert", "Master", "Legend"],
    datasets: [
      {
        label: "Earnings by Level",
        data: Object.values(earningsByLevel),
        backgroundColor: ["#3498db", "#2ecc71", "#e67e22", "#9b59b6"],
        borderRadius: 8,
      },
    ],
  };

  const lineData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Earnings Over Time",
        data: earningsOverTime,
        fill: true,
        backgroundColor: "rgba(52, 152, 219, 0.2)",
        borderColor: "#3498db",
        tension: 0.4,
      },
    ],
  };

  return (
    <div className={styles.earningsAnalytics}>
      <h2 className={styles.heading}>Earnings and Analytics</h2>

      {/* Total Earnings */}
      <div className={styles.totalEarnings}>
        <h3>Total Earnings</h3>
        <p className={styles.amount}>${totalEarnings.toLocaleString()}</p>
      </div>

      {/* Earnings by Level */}
      <div className={styles.chartSection}>
        <h3>Earnings by Teaching Level</h3>
        <Bar data={barData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
      </div>

      {/* Earnings Over Time */}
      <div className={styles.chartSection}>
        <h3>Earnings Over Time (Last Week)</h3>
        <Line data={lineData} options={{ responsive: true, plugins: { legend: { display: false } } }} />
      </div>

      {/* Revenue Milestones */}
      <div className={styles.milestones}>
        <h3>Revenue Milestones</h3>
        <p>
          Milestone: <span>$5000</span>
        </p>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${(totalEarnings / 5000) * 100}%` }}
          ></div>
        </div>
        <p className={styles.milestoneText}>
          ${totalEarnings} earned so far. ${5000 - totalEarnings} to next milestone!
        </p>
      </div>
    </div>
  );
};

export default EarningsAnalytics;
